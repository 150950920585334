import React from 'react'
import Logotipo from '@resources/imgs/footer-logotipo.png'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import RepresentanteSeatlle from '@resources/imgs/logo-seatlle-footer.png'
import RepresentanteEuropen from '@resources/imgs/logo-europen-footer.png'

const CatalogFooter: React.FC<any> = () => {
  return (
    <footer className="pt-4 pb-5">
      <div className="container">
        <div className="row justify-content-center justify-content-md-between align-items-center">
          <div className="col-md-6 text-center text-md-left">
            <figure className="mr-3 mb-0">
              <img src={Logotipo} className="img-fluid" alt="Logo" />
            </figure>
          </div>
          <div className="col-auto mt-md-0 mt-4 mb-4 text-center">
            <p style={{ fontSize: '12px' }}>Representante exclusivo</p>
            <img src={RepresentanteSeatlle} className="img-fluid mr-3" alt="" />
            <img src={RepresentanteEuropen} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="border-t-footer row justify-content-between">
          <div className="col-md-9 text-center text-md-left">
            <span>
              2024 PRO-SEED | Banco de Sêmen - Todos os Direitos Reservados -
              <Link to="https://proseed.com.br/politica-de-privacidade" target="_blank" className="text-white font-weight-bold"> Política de Privacidade</Link> |
              <Link to="https://proseed.com.br/termos-de-uso" target="_blank" className="text-white font-weight-bold"> Termos de Uso</Link>
            </span>
          </div>
          <div className="col-md-3 text-center d-flex justify-content-center mt-4 mt-md-0 justify-content-md-end text-md-right">
            <ul className="social">
              <li>
                <Link to="https://www.facebook.com/proseedbanco" target="_blank">
                  <FaFacebookF size={20} />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/proseed_brasil/" target="_blank">
                  <FaInstagram size={20} />
                </Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/pro-seed-banco-de-s%C3%AAmen/?viewAsMember=true" target="_blank">
                  <FaLinkedinIn size={20} />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/channel/UCZX0Myb-zJc4p93I4ewTMRw" target="_blank">
                  <FaYoutube size={20} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default CatalogFooter
