import React, { useCallback, useContext, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import MultiRangeSlider from 'multi-range-slider-react'
import { FaInfoCircle } from 'react-icons/fa'
import distanceBetweenEyes from '@resources/imgs/distanceEyes.png'
import DonorsContext from '@contexts/Donors'

interface Filter {
  filterType: string;
}

const SKIN_COLORS_BACKGROUND: any = {
  'Branca Clara': '#f8e5d7',
  'Branca': '#f6d6c1',
  'Branca Média Morena': '#e4b999',
  'Morena': '#cd9b78',
  'Morena Escura': '#a67356',
  'Negra': '#72452e',
  'Oliva': '#bd9975',
}

const EYE_COLORS_BACKGROUND: any = {
  'Azul': '#4174b4',
  'Azul Esverdeado': '#83c6bb',
  'Castanho': '#44240f',
  'Castanho Claro': '#9a7a62',
  'Castanho Esverdeado': '#8e9257',
  'Preto': '#272728',
  'Verde': '#759b57',
}

const HAIR_COLORS_BACKGROUND: any = {
  'Castanho': '#3f2a1b',
  'Castanho Claro': '#6c5238',
  'Grisalho': '#a6a8aa',
  'Loiro': '#af8f69',
  'Loiro Avermelhado': '#e8bd93',
  'Loiro Médio': '#8f6e48',
  'Preto': '#0e0e0e',
  'Ruivo': '#9e5c2c',
}

const ModalFilters: React.FC<Filter> = ({ filterType }) => {
  const { options, selectedFilters, setSelectedFilters, minMaxValues } = useContext(DonorsContext)

  const [weightRange, setWeightRange] = useState([selectedFilters.weightMin, selectedFilters.weightMax])
  const [heightRange, setHeightRange] = useState([selectedFilters.heightMin, selectedFilters.heightMax])

  const handleWeightChange = (e: any) => {
    setWeightRange([e.minValue, e.maxValue])
    setSelectedFilters({ ...selectedFilters, weightMin: e.minValue, weightMax: e.maxValue })
  }

  const handleHeightChange = (e: any) => {
    setHeightRange([e.minValue, e.maxValue])
    setSelectedFilters({ ...selectedFilters, heightMin: e.minValue, heightMax: e.maxValue })
  }

  const handleInputChange = useCallback((e: any, value?: string) => {
    value = String(value ?? e.target.id)

    const name = e.target.name

    if (e.target.checked) {
      setSelectedFilters((prevFilters: any) => ({
        ...prevFilters,
        [name]: [...prevFilters[name], value],
        hasFilter: true
      }))
    } else {
      setSelectedFilters((prevFilters: any) => ({
        ...prevFilters,
        [name]: prevFilters[name].filter((item: any) => item !== value),
        hasFilter: true
      }))
    }
  }, [setSelectedFilters])

  const [showTreatmentInfo, setShowTreatmentInfo] = useState({ show: false, option: '' })
  const handleTreatmentInfo = (option: string) => {
    return () => {
      if (showTreatmentInfo.option == option) {
        setShowTreatmentInfo({ show: false, option: '' })
      } else {
        setShowTreatmentInfo({ show: true, option })
      }
    }
  }

  const optionIsTreatment = (option: string) => {
    return option == 'FIV' || option == 'ICSI' || option == 'IIU' || option == 'Inseminação' || option == 'No ID Release' || option == 'ID Release' || option == 'Mapeamento Genético'
  }

  return (
    <>
      {filterType === 'all' && (
        <Accordion>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Tratamentos</Accordion.Header>
            <Accordion.Body>
              {options.treatmentTypes.map((option) => (
                <div className="form-group" key={`treatmentTypes-${option.key}`}>
                  <FaInfoCircle onClick={handleTreatmentInfo(option.value ?? option)} />
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="treatmentTypes"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.treatmentTypes.some((item: any) => item === String(option.key))}
                    />
                    {option.value}
                  </label>
                </div>
              ))}

              <div className={showTreatmentInfo.show ? 'd-block' : 'd-none'}>
                {showTreatmentInfo.option == 'FIV' && (
                  <>
                    <p className="text-left w-100 font-weight-bold">FIV</p>
                    <p className="font-sm">
                    Na Fertilização in Vitro (ou FIV clássica) a fertilização dos óvulos acontece em laboratório. Os óvulos e espermatozoides previamente selecionados são colocados em uma placa de cultivo e espera-se a fertilização natural. Os óvulos ficam expostos a milhões de espermatozoides e um deles consegue penetrar o óvulo sozinho e fecundá-lo.<br />
                    Amostras de FIV podem ser utilizadas para procedimentos ICSI.
                    </p>
                  </>
                )}
                {showTreatmentInfo.option == 'ICSI' && (
                  <>
                    <p className="text-left w-100 font-weight-bold">ICSI</p>
                    <p className="font-sm">
                    A ICSI (injeção intracitoplasmática de espermatozoides) é um tipo de FIV, onde a fertilização acontece pela injeção de um único espermatozoide em cada óvulo disponível. Já que os espermatozoides são altamente selecionados, esse tipo de procedimento não requer amostras de sêmen tão concentradas quanto os outros, viabilizando um custo mais competitivo para essas amostras.<br />
                    Não recomendamos a utilização de amostras de ICSI para tratamentos de FIV clássica.
                    </p>
                  </>
                )}
                {(showTreatmentInfo.option == 'IIU' || showTreatmentInfo.option == 'Inseminação') && (
                  <>
                    <p className="text-left w-100 font-weight-bold">Inseminação</p>
                    <p className="font-sm">
                      Essa técnica consiste na introdução dos espermatozoides diretamente na cavidade uterina da mulher, onde a fertilização acontecerá dentro do corpo da mulher. Apesar da baixa complexidade desse procedimento na clínica, ele requer uma amostra de sêmen com maior concentração e motilidade de espermatozoides, já que eles vão ter que nadar naturalmente até o óvulo. Por isso, amostras para inseminação são mais raras e caras.<br />
                      Não recomendamos a utilização de amostras de FIV/ICSI para procedimentos de inseminação.
                    </p>
                  </>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Bancos de Origem</Accordion.Header>
            <Accordion.Body>
              {options.spermBanks.map((option) => (
                <div className="form-group" key={`spermBanks-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="spermBanks"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.spermBanks.some((item: any) => item === String(option.key))}
                    />
                    {option.value === 'Pro-Seed' ? 'Pro-Seed Banco de Sêmen' : option.value}
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Tipo de Doador</Accordion.Header>
            <Accordion.Body>
              {options.donorTypes.map((option) => (
                <div className="form-group" key={`donorTypes-${option}`}>
                  <FaInfoCircle onClick={handleTreatmentInfo(option)} />
                  <label htmlFor={option} className="ml-2">
                    <input
                      type="checkbox"
                      name="donorTypes"
                      id={option}
                      onChange={(e) => handleInputChange(e, option)}
                      checked={selectedFilters?.donorTypes.some((item: any) => item === option)}
                    />
                    {option}
                  </label>
                </div>
              ))}

              <div className={showTreatmentInfo.show ? 'd-block' : 'd-none'}>
                {showTreatmentInfo.option == 'No ID Release' && (
                  <>
                    <p className="text-left w-100 font-weight-bold">No ID Release</p>
                    <p className="font-sm">
                      Por lei ou por opção os doadores anônimos não deram autorização para compartilhar sua identidade com a criança, e nenhum contato poderá ser estabelecido entre eles. Doadores anônimos dispõem da <b>mesma quantidade e qualidade de informações nos perfis dos catálogos.</b>
                    </p>
                  </>
                )}
                {showTreatmentInfo.option == 'ID Release' && (
                  <>
                    <p className="text-left w-100 font-weight-bold">ID Release</p>
                    <p className="font-sm">
                      Cada banco internacional tem o seu conceito de doador ID Aberto:<br />
                      SSB: o doador concordou em ter pelo menos uma forma de contato com a criança nascida de sua doação quando a criança completar 18 anos.<br />
                      ESB: doadores que deram permissão para que alguns dados da sua identidade fossem revelados à criança quando ela atingir a maioridade, 18 anos.<br />
                      Somente a criança poderá obter as informações do doador através do banco de sêmen de onde a amostra foi importada.<br />
                      <br />
                      <b>Importante:</b> Apesar de nos permitirem importar doadores de ID Aberto, o entendimento atual da ANVISA não libera a revelação da identidade dos doadores Open ID. Mas até a futura criança completar 18 anos isso pode mudar!
                    </p>
                  </>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="0">
            <Accordion.Header>Cor da Pele</Accordion.Header>
            <Accordion.Body>
              {options.skinColors.map((option) => (
                <div className="form-group" key={`skinColors-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="skinColors"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.skinColors.some((item: any) => item === String(option.key))}
                    />
                    {option.value} <div className="color" style={{ backgroundColor: SKIN_COLORS_BACKGROUND[option.value] }}></div>
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>Cor dos Olhos</Accordion.Header>
            <Accordion.Body>
              {options.eyeColors.map((option) => (
                <div className="form-group" key={`eyeColors-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="eyeColors"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.eyeColors.some((item: any) => item === String(option.key))}
                    />
                    {option.value} <div className="color" style={{ backgroundColor: EYE_COLORS_BACKGROUND[option.value] }}></div>
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Cor de Cabelo</Accordion.Header>
            <Accordion.Body>
              {options.hairColors.map((option) => (
                <div className="form-group" key={`hairColors-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="hairColors"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.hairColors.some((item: any) => item === String(option.key))}
                    />
                    {option.value} <div className="color" style={{ backgroundColor: HAIR_COLORS_BACKGROUND[option.value] }}></div>
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>Raça</Accordion.Header>
            <Accordion.Body>
              {options.races.map((option) => (
                <div className="form-group" key={`races-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="races"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.races.some((item: any) => item === String(option.key))}
                    />
                    {option.value}
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>Tipo Sanguíneo</Accordion.Header>
            <Accordion.Body>
              {options.bloodGroups.map((option) => (
                <div className="form-group" key={`bloodGroups-${option.key}`}>
                  <label htmlFor={option.key} className="ml-2">
                    <input
                      type="checkbox"
                      name="bloodGroups"
                      id={option.key}
                      onChange={(e) => handleInputChange(e, option.key)}
                      checked={selectedFilters?.bloodGroups.some((item: any) => item === String(option.key))}
                    />
                    {option.value}
                  </label>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>Mapeamento Genético</Accordion.Header>
            <Accordion.Body>
              <div className="form-group">
                <FaInfoCircle onClick={handleTreatmentInfo('Mapeamento Genético')} />
                <div className="custom-control custom-switch mb-3">
                  <input
                    type="checkbox"
                    name="geneticMappings"
                    className="custom-control-input"
                    id="geneticMappings"
                    onChange={(e) => handleInputChange(e, 'Mapeado')}
                    checked={selectedFilters?.geneticMappings.some((item: any) => item === String('Mapeado'))}
                  />
                  <label className="custom-control-label" htmlFor="geneticMappings">Mapeamento Genético</label>
                </div>
              </div>

              <div className={showTreatmentInfo.show ? 'd-block' : 'd-none'}>
                {showTreatmentInfo.option == 'Mapeamento Genético' && (
                  <>
                    <p className="text-left w-100 font-weight-bold">Mapeamento Genético</p>
                    <p className="font-sm">
                      O propósito desse mapeamento é que ele seja usado na análise de compatibilidade genética (matching) com a paciente, para identificar mutações genéticas recessivas em comum.<br />
                      Lembramos que o mapeamento genético do doador é só metade da informação necessária para essa análise!<br />
                      Recomendamos o aconselhamento genético para ambos os casos, seleção de doadores mapeados e não mapeados!
                    </p>
                  </>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>Doadores Novos</Accordion.Header>
            <Accordion.Body>
              <div className="custom-control custom-switch mb-3">
                <input
                  type="checkbox"
                  name="newDonors"
                  className="custom-control-input"
                  id="newDonors"
                  onChange={(e) => handleInputChange(e, '1')}
                  checked={selectedFilters?.newDonors.some((item: any) => item === String('1'))}
                />
                <label className="custom-control-label" htmlFor="newDonors">Doadores Novos</label>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>Altura</Accordion.Header>
            <Accordion.Body>
              <MultiRangeSlider
                min={minMaxValues.min_height}
                max={minMaxValues.max_height}
                step={10}
                ruler={false}
                minValue={heightRange[0] > 0 ? heightRange[0] : minMaxValues.min_height}
                maxValue={heightRange[1] > 0 && heightRange[1] > heightRange[0] ? heightRange[1] : minMaxValues.max_height}
                style={{ border: 'none', boxShadow: 'none', padding: '15px 10px', height: '60px' }}
                barLeftColor="#cecece"
                barInnerColor="#55d7e4"
                barRightColor="#cecece"
                thumbLeftColor="#3461a7"
                thumbRightColor="#3461a7"
                labels={[`${minMaxValues.min_height}cm`, `${minMaxValues.max_height}cm`]}
                onChange={handleHeightChange}
              />
              <div className="row m-0">
                <div className="col-6 form-group">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="number"
                      value={heightRange[0] > 0 ? heightRange[0] : minMaxValues.min_height}
                      min={minMaxValues.min_height}
                      max={minMaxValues.max_height}
                      onChange={(e) => handleHeightChange({ minValue: Number(e.target.value), maxValue: selectedFilters?.heightMax })}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">cm</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 form-group">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="number"
                      value={heightRange[1] > 0 ? heightRange[1] : minMaxValues.max_height}
                      min={minMaxValues.min_height}
                      max={minMaxValues.max_height}
                      onChange={(e) => handleHeightChange({ minValue: selectedFilters?.heightMin, maxValue: Number(e.target.value) })}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">cm</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-block">
                <p className="font-sm">
                  Aconselhamos que você selecione um intervalo de, pelo menos, +-5cm da altura desejada para que você não deixe de explorar uma opção de doador interessante por alguns centímetros de diferença!
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>Peso</Accordion.Header>
            <Accordion.Body>
              <MultiRangeSlider
                min={minMaxValues.min_weight}
                max={minMaxValues.max_weight}
                step={1}
                ruler={false}
                minValue={weightRange[0] > 0 ? weightRange[0] : minMaxValues.min_weight}
                maxValue={weightRange[1] > 0 && weightRange[1] > weightRange[0] ? weightRange[1] : minMaxValues.max_weight}
                style={{ border: 'none', boxShadow: 'none', padding: '15px 10px', height: '60px' }}
                barLeftColor="#cecece"
                barInnerColor="#55d7e4"
                barRightColor="#cecece"
                thumbLeftColor="#3461a7"
                thumbRightColor="#3461a7"
                labels={[`${minMaxValues.min_weight}kg`, `${minMaxValues.max_weight}kg`]}
                onChange={handleWeightChange}
              />
              <div className="row m-0">

                <div className="col-6 form-group">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="number"
                      min={minMaxValues.min_weight}
                      max={minMaxValues.max_weight}
                      value={weightRange[0] > 0 ? weightRange[0] : minMaxValues.min_weight}
                      onChange={(e) => handleWeightChange({ minValue: Number(e.target.value), maxValue: selectedFilters?.weightMax })}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">Kg</div>
                    </div>
                  </div>
                </div>
                <div className="col-6 form-group">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="number"
                      min={minMaxValues.min_weight}
                      max={minMaxValues.max_weight}
                      value={weightRange[1] > 0 ? weightRange[1] : minMaxValues.max_weight}
                      onChange={(e) => handleWeightChange({ minValue: selectedFilters?.weightMin, maxValue: Number(e.target.value) })}
                    />
                    <div className="input-group-prepend">
                      <div className="input-group-text">Kg</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-block">
                <p className="font-sm">
                  Aconselhamos que você selecione um intervalo de, pelo menos, +-5kg do peso desejado para que você não deixe de explorar uma opção de doador interessante por alguns kilos de diferença!
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {filterType == 'geneticMappings' && (
        <>
          <div className="form-group">
            <FaInfoCircle onClick={handleTreatmentInfo('Mapeamento Genético')} />
            <div className="custom-control custom-switch mb-3">
              <input
                type="checkbox"
                className="custom-control-input"
                name="geneticMappings"
                id="geneticMappings"
                onChange={(e) => handleInputChange(e, 'Mapeado')}
                checked={selectedFilters?.geneticMappings.some((item: any) => item === String('Mapeado'))}
              />
              <label className="custom-control-label" htmlFor="geneticMappings">Mapeamento Genético</label>
            </div>
          </div>

          {showTreatmentInfo.option == 'Mapeamento Genético' && (
            <>
              <p className="text-left w-100 font-weight-bold">Mapeamento Genético</p>
              <p className="font-sm">
                O propósito desse mapeamento é que ele seja usado na análise de compatibilidade genética (matching) com a paciente, para identificar mutações genéticas recessivas em comum.<br />
                Lembramos que o mapeamento genético do doador é só metade da informação necessária para essa análise!<br />
                Recomendamos o aconselhamento genético para ambos os casos, seleção de doadores mapeados e não mapeados!
              </p>
            </>
          )}

        </>
      )}

      {filterType == 'newDonors' && (
        <div className="custom-control custom-switch mb-3">
          <input
            type="checkbox"
            name="newDonors"
            className="custom-control-input"
            id="newDonors"
            onChange={(e) => handleInputChange(e, '1')}
            checked={selectedFilters?.newDonors.some((item: any) => item === String('1'))}
          />
          <label className="custom-control-label" htmlFor="newDonors">Doadores Novos</label>
        </div>
      )}

      {filterType !== 'height' && filterType !== 'weight' && filterType !== 'geneticMappings' && filterType !== 'newDonors' && filterType !== 'all' && (
        <>
          {filterType && options[filterType].map((option: any) => (
            <div className="form-group" key={option.value ? `${filterType}-${option.value}` : option}>
              {optionIsTreatment(option.value ?? option) && <FaInfoCircle onClick={(handleTreatmentInfo(option.value ?? option))} />}
              <label htmlFor={option.key ?? option} className="ml-2">
                <input
                  type="checkbox"
                  name={filterType}
                  id={option.key ?? option}
                  onChange={(e) => handleInputChange(e, option.key ?? null)}
                  checked={selectedFilters[filterType].some((item: any) => item === (String(option.key ?? option)))}
                />
                {(option.value ?? option) === 'Pro-Seed' ? 'Pro-Seed Banco de Sêmen' : (option.value ?? option)}
                {filterType === 'skinColors' && (
                  <div className="color" style={{ backgroundColor: SKIN_COLORS_BACKGROUND[option.value] }}></div>
                )}
                {filterType === 'eyeColors' && (
                  <div className="color" style={{ backgroundColor: EYE_COLORS_BACKGROUND[option.value] }}></div>
                )}
                {filterType === 'hairColors' && (
                  <div className="color" style={{ backgroundColor: HAIR_COLORS_BACKGROUND[option.value] }}></div>
                )}

              </label>
            </div>
          ))}

          {filterType == 'distanceBetweenEyes' && (<img src={distanceBetweenEyes} className="img-fluid" alt="" />)}

          {(filterType == 'treatmentTypes' || filterType == 'donorTypes') && (
            <div className={showTreatmentInfo.show ? 'd-block' : 'd-none'}>
              {showTreatmentInfo.option == 'FIV' && (
                <>
                  <p className="text-left w-100 font-weight-bold">FIV</p>
                  <p className="font-sm">
                    Na Fertilização in Vitro (ou FIV clássica) a fertilização dos óvulos acontece em laboratório. Os óvulos e espermatozoides previamente selecionados são colocados em uma placa de cultivo e espera-se a fertilização natural. Os óvulos ficam expostos a milhões de espermatozoides e um deles consegue penetrar o óvulo sozinho e fecundá-lo.<br />
                    Amostras de FIV podem ser utilizadas para procedimentos ICSI.
                  </p>
                </>
              )}
              {showTreatmentInfo.option == 'ICSI' && (
                <>
                  <p className="text-left w-100 font-weight-bold">ICSI</p>
                  <p className="font-sm">
                    A ICSI (injeção intracitoplasmática de espermatozoides) é um tipo de FIV, onde a fertilização acontece pela injeção de um único espermatozoide em cada óvulo disponível. Já que os espermatozoides são altamente selecionados, esse tipo de procedimento não requer amostras de sêmen tão concentradas quanto os outros, viabilizando um custo mais competitivo para essas amostras.<br />
                    Não recomendamos a utilização de amostras de ICSI para tratamentos de FIV clássica.
                  </p>
                </>
              )}
              {(showTreatmentInfo.option == 'IIU' || showTreatmentInfo.option == 'Inseminação') && (
                <>
                  <p className="text-left w-100 font-weight-bold">Inseminação</p>
                  <p className="font-sm">
                    Essa técnica consiste na introdução dos espermatozoides diretamente na cavidade uterina da mulher, onde a fertilização acontecerá dentro do corpo da mulher. Apesar da baixa complexidade desse procedimento na clínica, ele requer uma amostra de sêmen com maior concentração e motilidade de espermatozoides, já que eles vão ter que nadar naturalmente até o óvulo. Por isso, amostras para inseminação são mais raras e caras.<br />
                    Não recomendamos a utilização de amostras de FIV/ICSI para procedimentos de inseminação.
                  </p>
                </>
              )}
              {showTreatmentInfo.option == 'No ID Release' && (
                <>
                  <p className="text-left w-100 font-weight-bold">No ID Release</p>
                  <p className="font-sm">
                  Por lei ou por opção os doadores anônimos não deram autorização para compartilhar sua identidade com a criança, e nenhum contato poderá ser estabelecido entre eles. Doadores anônimos dispõem da <b>mesma quantidade e qualidade de informações nos perfis dos catálogos.</b>
                  </p>
                </>
              )}
              {showTreatmentInfo.option == 'ID Release' && (
                <>
                  <p className="text-left w-100 font-weight-bold">ID Release</p>
                  <p className="font-sm">
                    Cada banco internacional tem o seu conceito de doador ID Aberto:<br />
                    SSB: o doador concordou em ter pelo menos uma forma de contato com a criança nascida de sua doação quando a criança completar 18 anos.<br />
                    ESB: doadores que deram permissão para que alguns dados da sua identidade fossem revelados à criança quando ela atingir a maioridade, 18 anos.<br />
                    Somente a criança poderá obter as informações do doador através do banco de sêmen de onde a amostra foi importada.<br />
                    <br />
                    <b>Importante:</b> Apesar de nos permitirem importar doadores de ID Aberto, o entendimento atual da ANVISA não libera a revelação da identidade dos doadores Open ID. Mas até a futura criança completar 18 anos isso pode mudar!
                  </p>
                </>
              )}
            </div>
          )}
        </>
      )}

      {filterType == 'height' && (
        <MultiRangeSlider
          min={minMaxValues.min_height}
          max={minMaxValues.max_height}
          step={10}
          ruler={false}
          minValue={heightRange[0] > 0 ? heightRange[0] : minMaxValues.min_height}
          maxValue={heightRange[1] > 0 && heightRange[1] > heightRange[0] ? heightRange[1] : minMaxValues.max_height}
          id={filterType}
          style={{ border: 'none', boxShadow: 'none', padding: '15px 10px', height: '60px' }}
          barLeftColor="#cecece"
          barInnerColor="#55d7e4"
          barRightColor="#cecece"
          thumbLeftColor="#3461a7"
          thumbRightColor="#3461a7"
          labels={[`${minMaxValues.min_height}cm`, `${minMaxValues.max_height}cm`]}
          onChange={handleHeightChange}
        />
      )}
      {filterType == 'height' && (
        <>
          <div className="row m-0">
            <div className="col-6 form-group">
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="number"
                  min={minMaxValues.min_height}
                  max={minMaxValues.max_height}
                  value={heightRange[0] > 0 ? heightRange[0] : minMaxValues.min_height}
                  onChange={(e) => handleHeightChange({ minValue: Number(e.target.value), maxValue: selectedFilters?.heightMax })}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">cm</div>
                </div>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="number"
                  min={minMaxValues.min_height}
                  max={minMaxValues.max_height}
                  value={heightRange[1] > 0 ? heightRange[1] : minMaxValues.max_height}
                  onChange={(e) => handleHeightChange({ minValue: selectedFilters?.heightMin, maxValue: Number(e.target.value) })}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">cm</div>
                </div>
              </div>
            </div>
          </div>

          <p className="font-sm">
            Aconselhamos que você selecione um intervalo de, pelo menos, +-5cm da altura desejada para que você não deixe de explorar uma opção de doador interessante por alguns centímetros de diferença!
          </p>
        </>
      )}

      {filterType == 'weight' && (
        <MultiRangeSlider
          min={minMaxValues.min_weight}
          max={minMaxValues.max_weight}
          step={1}
          ruler={false}
          minValue={weightRange[0] > 0 ? weightRange[0] : minMaxValues.min_weight}
          maxValue={weightRange[1] > 0 && weightRange[1] > weightRange[0] ? weightRange[1] : minMaxValues.max_weight}
          style={{ border: 'none', boxShadow: 'none', padding: '15px 10px', height: '60px' }}
          barLeftColor="#cecece"
          barInnerColor="#55d7e4"
          barRightColor="#cecece"
          thumbLeftColor="#3461a7"
          thumbRightColor="#3461a7"
          labels={[`${minMaxValues.min_weight}kg`, `${minMaxValues.max_weight}kg`]}
          onChange={handleWeightChange}
        />
      )}
      {filterType == 'weight' && (
        <>
          <div className="row m-0">
            <div className="col-6 form-group">
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="number"
                  min={minMaxValues.min_weight}
                  max={minMaxValues.max_weight}
                  value={weightRange[0] > 0 ? weightRange[0] : minMaxValues.min_weight}
                  onChange={(e) => handleWeightChange({ minValue: Number(e.target.value), maxValue: selectedFilters?.weightMax })}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">Kg</div>
                </div>
              </div>
            </div>
            <div className="col-6 form-group">
              <div className="input-group mb-2">
                <input
                  className="form-control"
                  type="number"
                  min={minMaxValues.min_weight}
                  max={minMaxValues.max_weight}
                  value={weightRange[1] > 0 ? weightRange[1] : minMaxValues.max_weight}
                  onChange={(e) => handleWeightChange({ minValue: selectedFilters?.weightMin, maxValue: Number(e.target.value) })}
                />
                <div className="input-group-prepend">
                  <div className="input-group-text">Kg</div>
                </div>
              </div>
            </div>
          </div>

          <p className="font-sm">
            Aconselhamos que você selecione um intervalo de, pelo menos, +-5kg do peso desejado para que você não deixe de explorar uma opção de doador interessante por alguns kilos de diferença!
          </p>
        </>
      )}
      {/* Rest of the component */}
    </>
  )
}

export default ModalFilters