import React, { useState, ChangeEvent, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import '@resources/css/register.css'
import { RiUserFollowLine } from 'react-icons/ri'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import axios from 'axios'
import { PhoneInput, defaultCountries, parseCountry, buildCountryData } from 'react-international-phone'
import 'react-international-phone/style.css'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Auth from '@services/Auth'
import { htmlErrorMessage } from '@helpers/Validation'
import AuthContext from '@contexts/Auth'
import { BsChevronRight } from 'react-icons/bs'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import IDoctor from '@interfaces/IDoctor'
import { USER_DOCTOR_TYPES } from '@constants/userDoctorTypes'

const animatedComponents = makeAnimated()

const RegisterDoctor = () => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)

  const [ doctor, setDoctor ] = useState<IDoctor>({} as IDoctor)

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  const countriesWithBrNewMask = defaultCountries.map((country: any) => {
    const parsedCountry = parseCountry(country)

    if (parsedCountry.name === 'Brazil') {
      parsedCountry.format = '(..) .....-....'
    }

    return buildCountryData(parsedCountry)
  })

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const registerFullFilled: boolean = (doctor.name && doctor.whatsapp && doctor.email && doctor.password && doctor.password_confirmation && doctor.doctor_type) ? true : false

    if (!registerFullFilled) {
      const firstInvalidField = [
        'name', 'whatsapp', 'email', 'password', 'password_confirmation', 'doctor_type'
      ].find(field => !doctor[field as keyof IDoctor])

      if (firstInvalidField) {
        const fieldElement = document.getElementsByName(firstInvalidField)[0]
        if (fieldElement) {
          fieldElement.focus()
        }
      }

      SweetAlert.fire({
        title: 'Erro!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        confirmButtonText: 'Ok',
      })

      return
    }

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Realizando cadastro',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Auth.registerDoctor(doctor).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)

      SweetAlert.close()

      navigate('/verificar-email')
    }).catch((e) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, 'Não foi possível realizar o cadastro'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoctor({ ...doctor, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDoctor({ ...doctor, [e.target.name]: e.target.checked })
  }

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  const togglePasswordConfirmationVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  return (
    <div className="container">
      <div className="row m-0 mt-4 mb-4 align-items-center justify-content-between">
        <div className="col-6 col-sm-6 col-md-3">
          <img src={LogoProssed} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="pt-2 pb-2">
        <div className="row m-0">
          <div className="col-auto pr-0">
            <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
              Catálogo
            </a>
          </div>
          <div className="col-auto pl-1 pr-1">
            <BsChevronRight size={10} />
          </div>
          <div className="col-auto p-0">
            <a href="#" className="id d-flex align-items-center font-weight-bold">
              Cadastro de Médico
            </a>
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="register-section">
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-8">
                <div className="form-group">
                  <label>Nome e sobrenome <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!doctor.name ? 'is-invalid' : ''}`}
                    name="name"
                    value={doctor.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Whatsapp <span>*</span></label>
                  <PhoneInput
                    defaultCountry="br"
                    className={doctor.whatsapp?.length <= 3 ? 'is-invalid' : ''}
                    countries={countriesWithBrNewMask}
                    inputClassName="w-100"
                    name="whatsapp"
                    value={doctor.whatsapp ?? ''}
                    onChange={(whatsapp) => setDoctor({ ...doctor, whatsapp })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>E-mail <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!doctor.email ? 'is-invalid' : ''}`}
                    name="email"
                    value={doctor.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Senha <span>*</span></label>

                  <div className="inputs d-flex justify-content-between">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={'form-control mb-3 custom-placeholder' + (!doctor.password ? ' is-invalid' : '')}
                      name="password"
                      value={doctor.password ?? ''}
                      onChange={handleInputChange}
                      required
                    />
                    <button
                      type="button"
                      className={`btn eye-icon px-3 ${!doctor.password ? 'mr-3' : ''}`}
                      onClick={(e) => togglePasswordVisibility(e)}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="closed-eye-icon text-dark" />
                      ) : (
                        <FaEye className="open-eye-icon text-dark" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Confirme sua senha <span>*</span></label>

                  <div className="inputs d-flex justify-content-between">
                    <input
                      type={showPasswordConfirmation ? 'text' : 'password'}
                      className={'form-control mb-3 custom-placeholder' + (!doctor.password_confirmation ? ' is-invalid' : '')}
                      name="password_confirmation"
                      value={doctor.password_confirmation ?? ''}
                      onChange={handleInputChange}
                      required
                    />
                    <button
                      type="button"
                      className={`btn eye-icon px-3 ${!doctor.password_confirmation ? 'mr-3' : ''}`}
                      onClick={(e) => togglePasswordConfirmationVisibility(e)}
                    >
                      {showPasswordConfirmation ? (
                        <FaEyeSlash className="closed-eye-icon text-dark" />
                      ) : (
                        <FaEye className="open-eye-icon text-dark" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-6 col-mb-6 col-lg-4">
                <div className="form-group">
                  <label>Perfil <span>*</span></label>
                  <Select
                    className={`react-select ${!doctor.doctor_type ? 'is-invalid' : ''}`}
                    placeholder={<div>Selecione</div>}
                    components={animatedComponents}
                    options={USER_DOCTOR_TYPES}
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                    value={doctor.doctor_type ? { value: doctor.doctor_type, label: doctor.doctor_type } : null}
                    onChange={(e: any) => { setDoctor({ ...doctor, doctor_type: e.label }) }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-mb-6 col-lg-4">
                <div className="form-group">
                  <label>Nome da Clínica</label>
                  <input
                    type="text"
                    placeholder=""
                    className="form-control"
                    name="clinic_name"
                    value={doctor.clinic_name ?? ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="register-footer mt-3">
          <div className="row">
            <div className="col-12">
              <div className="checkbox-terms d-flex">
                <input
                  type="checkbox"
                  className="custom-control"
                  id="terms_accepted"
                  name="terms_accepted"
                  value={doctor.terms_accepted ? 'on' : 'off'}
                  onChange={handleCheckboxChange}
                />
                <label className="my-4 pl-2" htmlFor="terms_accepted">
                  Concordo com os
                  <Link to="https://proseed.com.br/termos-de-uso" target="_blank"> Termos de Uso</Link> e
                  <Link to="https://proseed.com.br/politica-de-privacidade" target="_blank"> Política de Privacidade</Link>
                </label>
              </div>
            </div>
            <div className="d-flex gap-20">
              <Link className="return-btn btn btn-primary mt-3" to="/">
                <MdKeyboardArrowLeft />
                  Retornar
              </Link>
              <Button type="submit" className="register-btn btn btn-primary mt-3" disabled={!doctor.terms_accepted}>
                  Cadastrar
                <RiUserFollowLine />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default RegisterDoctor
