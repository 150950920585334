import axios from 'axios'

export const personalPatient = (data: FormData): Promise<any> => axios.post('v1/catalogo/profile/personal/patient', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const personalDoctor = (data: FormData): Promise<any> => axios.post('v1/catalogo/profile/personal/doctor', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const password = (data: FormData): Promise<any> => axios.post('v1/catalogo/profile/password', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const address = (data: FormData): Promise<any> => axios.post('v1/catalogo/profile/address', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const partner = (data: FormData): Promise<any> => axios.post('v1/catalogo/profile/partner', data, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const excludeRequest = (): Promise<any> => axios.post('v1/catalogo/profile/exclude-request')