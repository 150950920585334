import React, { useState } from 'react'
import '@resources/css/tabs/exams.css'
import IDonor from '@interfaces/IDonor'
import { FaCheck, FaFile, FaImage, FaInfoCircle } from 'react-icons/fa'
import IExamDocument from '@interfaces/IExamDocument'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Lightbox from 'yet-another-react-lightbox'
import Download from 'yet-another-react-lightbox/plugins/download'
import Share from 'yet-another-react-lightbox/plugins/share'
import Captions from 'yet-another-react-lightbox/plugins/captions'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import pdf from '@resources/files/orientacoes-geneticas.pdf'

type Props = {
  donor: IDonor
}

const Exams: React.FC<Props> = ({ donor }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<string>('')
  const [currentImageTitle, setCurrentImageTitle] = useState<string>('')

  const handleImageClick = (file: string, title: string) => {
    setCurrentImage(file)
    setCurrentImageTitle(title)
    setIsOpen(true)
  }

  const cgtExomeTooltip = (
    <Tooltip id="cgt-exome-tooltip">
      O mapeamento genético do doador é só metade da informação necessária para análise de compatibilidade genética. Confira documento anexo para mais explicações do mapeamento genético!
    </Tooltip>
  )

  const hlacTooltip = (
    <Tooltip id="hla-c-tooltip">
      A determinação do perfil do HLA-C dos doadores é usada para estudar a compatibilidade com os receptores KIR da mãe. Consulte seu médico para mais infos!
    </Tooltip>
  )

  const donorIsNacional = donor.sperm_bank?.name === 'Pro-Seed'

  return (
    <>
      <section className="donator-about">
        <div className="drugs">
          <h5 className="mb-0 text-capitalize">Avaliação Final: <span className="approved-donor">Doador Aprovado</span></h5>
        </div>
        <div className="content exams">
          <div className="d-flex flex-wrap">
            <div className="exam-item">
              <h6><span className="text-capitalize">Análise seminal:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Entrevista com Biomédico:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Triagem médica com urologista:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Exame clínico especializado:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Exames sorológicos:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Exames de cultura seminal:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Quarentena das amostras:</span> <FaCheck className="text-success" /></h6>
            </div>
            <div className="exam-item">
              <h6><span className="text-capitalize">Repetição dos exames para liberação das amostras:</span> <FaCheck className="text-success" /></h6>
            </div>
          </div>
        </div>
        {donorIsNacional && (
          <div className="transmissible">
            <h5>Infecções Sexualmente Transmissíveis</h5>
            <div className="row">
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">HIV:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">HTLV 1 e 2:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Hepatite B e C:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Sífilis:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Chlamydia Trachomatis:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Mycoplasma/Ureaplasma:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Neisseria Gonorrhoeae:</span> Negativo</p>
              </div>
            </div>
          </div>
        )}
        {donorIsNacional && (
          <div className="triage">
            <h5>Triagem Médica do Doador</h5>
            <div className="row">
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Albinismo:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Autismo:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Hemofilia:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Hemoglobinopatia:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Hipercolesterolemia hereditária:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Neurofibromatose:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Esclerose Tuberosa:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Depressão:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Epilepsia:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Deficiência Física:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Deficiência Mental:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Doenças Psiquiatricas:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Esquisofrenia:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Pressão Arterial:</span> Negativo</p>
              </div>
            </div>
          </div>
        )}
        {donorIsNacional && (
          <div className="family">
            <h5>Histórico Médico Familiar do Doador</h5>
            <div className="row content-line">
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Câncer na Prostata:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Câncer de Cólon:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Câncer de Mama:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Câncer no Ovário:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Câncer no Pâncreas:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Asma:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Diabetes Juvenil:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Epilepsia:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Hipertensão:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Psicose:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Artrite Reumatóide:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Doença Coronária Precoce:</span> Negativo</p>
              </div>
              <div className="col-12 col-lg-6">
                <p><span className="text-capitalize">Fibrose Cística:</span> Negativo</p>
              </div>
            </div>
          </div>
        )}
        <div className="genetic">
          <h5>Histórico Genético do Doador</h5>
          <div className="row">
            <div className="col-12 col-lg-6">
              <p><span className="text-capitalize">Cariótipo:</span> 46 XY, Normal</p>
            </div>
            {donorIsNacional && (
              <>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Anemia Falciforme:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Talassemia:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Eletroforese Hemoglobina:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Lábio Leporino:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Espinha Bifida:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Hipospadia:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Mal Formação Cardíaca:</span> Negativo</p>
                </div>
                <div className="col-12 col-lg-6">
                  <p><span className="text-capitalize">Luxação Congênitado Quadril:</span> Negativo</p>
                </div>
              </>
            )}
            {donor.cgt_exome && (
              <div className="col-12 col-lg-6">
                <div>
                  <span>Mapeamento Genético:</span> {donor.cgt_exome}
                  <OverlayTrigger placement="top" overlay={cgtExomeTooltip}>
                    <span className="ml-1"><FaInfoCircle /></span>
                  </OverlayTrigger>
                </div>
              </div>
            )}
            {(donor.hla_c && donor.hla_c !== 'N/D' && donor.hla_c !== 'Não') && (
              <div className="col-12 col-lg-6">
                <div>
                  <span>HLA-C:</span> {donor.hla_c}
                  <OverlayTrigger placement="top" overlay={hlacTooltip}>
                    <span className="ml-1"><FaInfoCircle /></span>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </div>
        </div>

        {donor.exam_documents && donor.exam_documents.length > 0 && (
          <div className="content exam-documents">
            <h5 className="mb-4">Exames</h5>
            <div className="">
              {donor.exam_documents.map((doc: IExamDocument) => (
                <div className="exam-document-item text-center" key={doc.id}>
                  <p className="mb-0"><span>{doc.name}</span></p>
                  {doc.type === 'pdf' ? (
                    <a href={doc.file} target="_blank" rel="noreferrer">
                      <button className="btn btn-outline-secondary d-flex justify-content-center align-items-center">
                        <FaFile />
                      </button>
                    </a>
                  ) : (
                    <button
                      className="btn btn-outline-secondary d-flex justify-content-center align-items-center"
                      onClick={() => handleImageClick(doc.file, doc.name)}
                    >
                      <FaImage />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        {isOpen && (
          <Lightbox
            open={isOpen}
            close={() => setIsOpen(false)}
            slides={[{ src: currentImage, title: currentImageTitle }]}
            carousel={{ finite: true, }}
            plugins={[Download, Share, Captions, Zoom]}
            render={{
              buttonPrev: () => null,
              buttonNext: () => null,
            }}
          />
        )}
      </section>

      {donorIsNacional && (
        <section className="know-about-caracteristics">
          <div className="row align-items-center">
            <div className="col-12 col-md-8 text-center text-md-left">
              <h5 className="mb-0">Mapeamento Genético de Doadores de Sêmen do Pro-Seed</h5>
            </div>
            <div className="col-12 col-sm-12 col-md-4 text-center text-md-right mt-2 mt-md-0">
              <a href={pdf} target="_blank" rel="noreferrer">
                <button className="btn btn-primary">Orientações</button>
              </a>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default Exams
