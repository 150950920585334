import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import '@resources/css/forgotpassword.css'
import { FaEnvelope } from 'react-icons/fa'

const ForgotPassword: React.FC<any> = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ isSent, setIsSent ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.forgotPassword(email).then(() => {
      setError(null)
      setIsSent(true)
    }).catch(e => {
      setIsSent(false)
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a recuperação de senha. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  return (
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2">
        <div className="card card-full">
          <div className="card-header d-flex flex-column align-items-center pt-3">
            <figure>
              <FaEnvelope size={120} style={{ color: '#FFA9BF' }} />
            </figure>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body d-flex flex-column align-items-center">
              {isSent && (
                <div className="alert alert-success" role="alert">
                  Foi enviado um email para <strong>{email}</strong> com instruções para recuperar a sua senha.
                </div>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              <h3 className="mt-3">
                Informe o seu email para a recuperação de senha.
              </h3>

              <div className="col-12 col-md-6 mt-3">
                <div className="form-group">
                  <label htmlFor="email">Seu endereço de e-mail</label>
                  <input
                    type="email"
                    className={'form-control' + (error ? ' is-invalid' : '')}
                    id="email"
                    placeholder="name@examplo.com"
                    onChange={e => setEmail(e.target.value)}
                    disabled={isLoading}
                    required
                  />
                </div>
              </div>

            </div>

            <div className="card-footer d-flex flex-column align-items-center">
              <button type="submit" className="btn btn-primary send-btn btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                <span>Receber link de recuperação</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
              </button>
            </div>

            <div className="text-center mt-3">
              <h5>Lembrou a sua senha?</h5>
              <Link to="/?login=1">Efetue o login</Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword