import React, { useContext, useEffect, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import LogoSeattle from '@resources/imgs/seattle.png'
import LogoEuropean from '@resources/imgs/european.webp'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import Userphoto from '@resources/imgs/userphoto.svg'
import New from '@resources/imgs/icon-new.svg'
import Trompas from '@resources/imgs/tratament-3.png'
import Embriao from '@resources/imgs/tratament-1.png'
import Ovulos from '@resources/imgs/tratament-2.png'
import '@resources/fontes/fontes.css'
import '@resources/css/donators.css'
import Login from '@screens/Auth/Login/Login'
import IDonor from '@interfaces/IDonor'
import AuthContext from '@contexts/Auth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IoChevronDown } from 'react-icons/io5'

type Props = {
  donor?: IDonor
}

// Global variable to track if the modal has been shown
let globalHasShownModal = false

const Donators: React.FC<Props> = ({ donor }) => {
  const { user } = useContext(AuthContext)

  const [showModal, setShowModal] = useState(false)

  const navigate = useNavigate()

  const [ searchParams ] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('login') && searchParams.get('login') === '1' && !globalHasShownModal) {
      setShowModal(true)
      globalHasShownModal = true // Set the global variable to true after showing the modal
    }
  }, [searchParams])

  const handleShowModal = () => {
    if (user) {
      navigate(`/doadores/${donor?.internal_id?.toLowerCase()}`)
      window.scrollTo(0, 0)
    }

    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const showContent = (e: any) => {
    e.target.closest('.show-less').classList.toggle('show-more')
  }

  return (
    <>
      <Card className="donators flex-column flex-md-row mb-3">
        <Card.Header className="card-header-swiper d-flex justify-content-sm-center border-right-sm-0 p-1" id="card-swiper">
          <div className="left-side">
            <figure className="d-flex justify-content-center mb-3 mr-0">
              <img className="photo" src={donor?.avatar || Userphoto} alt="" />
              <figcaption className="">
                <div className="like">
                  <div className="id d-md-none d-sm-block">ID: {donor?.internal_id}</div>
                </div>
                {donor?.is_new_donor ? (
                  <div className="icon-new">
                    <img src={New} alt="" />
                  </div>
                ) : null}
              </figcaption>
            </figure>
          </div>

          <div className="left-side-dados">
            <Card.Title className="card-title-swiper m-0">
              <div className="d-flex align-items-center flex-column flex-md-row justify-content-between">
                <div id="dados">
                  <div className="id d-none d-md-block">ID: {donor?.internal_id}</div>
                  <div className="profissao" title={donor?.occupation}>{donor?.occupation?.length > 18 ? `${donor?.occupation.slice(0, 18)}...` : donor?.occupation}</div>
                  <div className="idade">
                    {donor?.age && donor?.nickname && donor?.sperm_bank?.name !== 'Pro-Seed' && (
                      <>
                        {`${donor?.age} anos - ${donor?.nickname}`}<br />
                        {donor?.donor_type}
                      </>
                    )}
                    {donor?.age && (!donor?.nickname || donor?.sperm_bank?.name === 'Pro-Seed') && (
                      `${donor?.age} anos`
                    )}
                    {!donor?.age && donor?.nickname && donor?.sperm_bank?.name !== 'Pro-Seed' && (
                      <>
                        {donor?.nickname}<br />
                        {donor?.donor_type}
                      </>
                    )}
                  </div>
                  <div className="amostra">{donor?.sperm_bank?.name === 'Pro-Seed' ? 'Amostra Nacional' : 'Amostra Internacional'}</div>
                </div>
                <figure className="mt-2">
                  {donor?.sperm_bank?.name === 'Pro-Seed' ? (
                    <img src={LogoProssed} alt="" />
                  ) : donor?.sperm_bank?.name === 'European Sperm Bank' ? (
                    <img src={LogoEuropean} alt="" />
                  ) : donor?.sperm_bank?.name === 'Seattle Sperm Bank' ? (
                    <img src={LogoSeattle} alt="" />
                  ) : (
                    <img src={LogoProssed} alt="" />
                  )}
                </figure>
              </div>
              <p className="text-dentro m-0">
                {donor?.proseed_description?.length > 180 ? `${donor?.proseed_description.slice(0, 180)}...` : donor?.proseed_description}
              </p>
            </Card.Title>

          </div>
        </Card.Header>

        <Card.Body className="card-body-swiper">
          <p className="text-fora col-12 d-none m-0">
            {donor?.proseed_description}
          </p>
          <div className="show-less row m-0">
            <button className="show-more-btn"><IoChevronDown size={30} onClick={showContent} /></button>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Cor da Pele
              </div>
              <div className="result d-flex">
                <span>{donor?.skin_color?.name}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Cor dos Olhos
              </div>
              <div className="result d-flex">
                <span>{donor?.eye_color?.name}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Cor do Cabelo
              </div>
              <div className="result d-flex">
                <span>{donor?.hair_color?.name}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Raça
              </div>
              <div className="result d-flex">
                <span>{donor?.race?.name}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Tipo Sanguíneo
              </div>
              <div className="result d-flex">
                <span>{donor?.blood_group?.name}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Altura
              </div>
              <div className="result d-flex">
                <span>{donor?.height}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Peso
              </div>
              <div className="result d-flex">
                <span>{donor?.weight}Kg</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 custom-grid">
              <div className="title">
              Disponibilidade
              </div>
              <div className="result d-flex">
                <span>Envio imediato</span>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className={`col-12 paddingzero ${donor?.available_treatment_names && donor?.available_treatment_names?.length > 0 ? 'pt-1' : 'pt-4'}`}>
              <div className="content">
                <div className="m-0 row align-items-center pt-3">
                  <div className="col-12 responsivecol6 col-lg-6 pl-0 text-left">
                    <div className="envio">
                      <span>Disponível para tratamento de:</span>
                    </div>
                    {donor?.available_treatment_names && donor?.available_treatment_names?.length > 0 ? (
                      <div className="tratamentos d-flex pt-2 gap-5">
                        {donor?.available_treatment_names.includes('ICSI') && (
                          <div className="result d-flex">
                            <img src={Ovulos} alt="" />
                            <span>ICSI</span>
                          </div>
                        )}
                        {donor?.available_treatment_names.includes('FIV') && (
                          <div className="result d-flex">
                            <img src={Embriao} alt="" />
                            <span>FIV</span>
                          </div>
                        )}
                        {donor?.available_treatment_names.includes('IIU') && (
                          <div className="result d-flex">
                            <img src={Trompas} alt="" />
                            <span>Inseminação</span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="tratamentos d-flex pt-2">
                        <div className="result d-flex">
                          <span>Não disponível</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 responsivecol6 buttons-area col-lg-6 pt-2">
                    <div className="button d-flex align-items-center justify-content-between">
                      <button className="order-now btn btn-medium-primary" onClick={handleShowModal}>
                      Perfil completo
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} size="xl">
        <Modal.Header className="border-0">
          <button type="button" className="close" onClick={handleCloseModal}>
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>

        <Modal.Body>
          <Login donorId={donor?.internal_id} />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Donators
