import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import { FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa'

const ResetPassword: React.FC<any> = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  const [ passwordConfirmation, setPasswordConfirmation ] = useState<string>('')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ error, setError ] = useState<string|null>(null)
  const [ isSuccessful, setIsSuccessful ] = useState(false)

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false)

  const { search } = useLocation()

  const token = new URLSearchParams(search).get('token')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    $Auth.resetPassword(token as string, email, password, passwordConfirmation).then(() => {
      setIsSuccessful(true)
      setError(null)
    }).catch(e => {
      setIsSuccessful(false)
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar a redefinição de senha. Favor tentar novamente.')
    }).finally(() => setIsLoading(false))
  }

  const toggleNewPasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowNewPassword(!showNewPassword)
  }

  const toggleNewPasswordConfirmationVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowNewPasswordConfirmation(!showNewPasswordConfirmation)
  }

  return (
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2">
        <div className="card card-full">
          <div className="card-header d-flex flex-column align-items-center">
            <figure>
              <FaEnvelope size={120} style={{ color: '#FFA9BF' }} />
            </figure>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-body d-flex flex-column align-items-center">
              {isSuccessful && (
                <div className="alert alert-success mb-0" role="alert">
                  A sua senha foi alterada com sucesso. <Link to="/?login=1" className="alert-link">Clique aqui</Link> para efetuar o login.
                </div>
              )}

              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}

              <h3 className="mt-3">
                Redefinição de senha
              </h3>

              {!isSuccessful && (
                <>
                  <div className="col-12 col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="email" className="mb-0">Seu endereço de e-mail</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        onChange={e => setEmail(e.target.value)}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="password" className="mb-0">Sua nova senha</label>

                      <div className="inputs d-flex justify-content-between">
                        <input
                          type={showNewPassword ? 'text' : 'password'}
                          name="password"
                          id="password"
                          className="form-control"
                          placeholder="Senha"
                          onChange={e => setPassword(e.target.value)}
                          disabled={isLoading}
                          required
                        />
                        <button
                          type="button"
                          className="btn eye-icon px-3"
                          onClick={(e) => toggleNewPasswordVisibility(e)}
                        >
                          {showNewPassword ? (
                            <FaEyeSlash className="closed-eye-icon text-dark" />
                          ) : (
                            <FaEye className="open-eye-icon text-dark" />
                          )}
                        </button>
                      </div>
                      <i className="form-text">(mínimo de 6 caracteres)</i>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-3">
                    <div className="form-group">
                      <label htmlFor="password_confirmation" className="mb-0">Confirme sua nova senha</label>

                      <div className="inputs d-flex justify-content-between">
                        <input
                          type={showNewPasswordConfirmation ? 'text' : 'password'}
                          name="password_confirmation"
                          id="password_confirmation"
                          className="form-control"
                          placeholder="Confirme sua nova senha"
                          onChange={e => setPasswordConfirmation(e.target.value)}
                          disabled={isLoading}
                          required
                        />
                        <button
                          type="button"
                          className="btn eye-icon px-3"
                          onClick={(e) => toggleNewPasswordConfirmationVisibility(e)}
                        >
                          {showNewPasswordConfirmation ? (
                            <FaEyeSlash className="closed-eye-icon text-dark" />
                          ) : (
                            <FaEye className="open-eye-icon text-dark" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {!isSuccessful && (
              <div className="card-footer d-flex flex-column align-items-center">
                <button type="submit" className="btn btn-primary send-btn btn-block d-flex align-items-center justify-content-center" disabled={isLoading}>
                  <span>Redefinir senha</span> {isLoading && <Spinner animation="border" className="ml-2" size="sm" />}
                </button>
              </div>
            )}

            <div className="text-center mt-3">
              <h5>Lembrou a sua senha?</h5>
              <Link to="/?login=1">Efetue o login</Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
