import React, { useContext, useEffect, useRef, useState } from 'react'
import LoginContext from '@contexts/Login'
import { Link, useSearchParams } from 'react-router-dom'
import * as $Auth from '@services/Auth'
import AuthContext from '@contexts/Auth'
import axios from 'axios'
import '@resources/css/securitycode.css'
import LoginSuccessModal from '@components/Modals/LoginSuccessModal'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { FaShieldAlt } from 'react-icons/fa'

const SecurityCode: React.FC<any> = () => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)
  const { email, password, isLoading, setIsLoading, error, setError } = useContext(LoginContext)

  const [ donorId, setDonorId ] = useState<string | null>(null)
  const [ securityCode, setSecurityCode ] = useState<string[]>(['', '', '', ''])
  const [ isLoadingResend, setIsLoadingResend ] = useState(false)
  const inputRefs = useRef<HTMLInputElement[]>([])

  const [ searchParams ] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('doador')) {
      const donorId = searchParams.get('doador')

      if (donorId) {
        setDonorId(donorId)
      }
    } else {
      setDonorId(null)
    }
  }, [ searchParams ])

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...securityCode]
    values[index] = event.target.value
    setSecurityCode(values)

    if (event.target.value.length === 1) {
      const inputRef = inputRefs.current[index + 1] || inputRefs.current[index]
      inputRef.focus()
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      event.preventDefault()

      setSecurityCode(securityCode => {
        const values = [...securityCode]
        values[index] = ''
        return values
      })

      if (inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus()
      }
    }
  }

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault()
    const paste = event.clipboardData.getData('text')

    let index = 0

    for (let i = 0; i < 4; i++) {
      inputRefs.current[i].value = paste[index]

      index++
      if (index === 4)
        break
    }

    setSecurityCode(inputRefs.current.map(input => input.value))
  }

  const resend = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    setIsLoadingResend(true)

    setError(null)

    $Auth.securityCode(email, password).catch(e => {
      setError(e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.')
    }).finally(() => setIsLoadingResend(false))
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    setError(null)

    $Auth.login(email, password, securityCode.join('')).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)

      handleShowSuccessModal()
    }).catch((e) => {
      setError(
        e.response?.data?.message || 'Ocorreu um erro ao tentar realizar o login. Favor tentar novamente.'
      )
    }).finally(() => setIsLoading(false))
  }

  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const handleShowSuccessModal = () => {
    setShowSuccessModal(true)
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false)
  }

  return (
    <div className="row">

      <div className="col-12 col-md-6 offset-md-3">
        <div className="card securitycode card-full">
          <div className="card-header d-flex flex-column align-items-center pt-3">
            <figure>
              <FaShieldAlt size={120} style={{ color: '#FFA9BF' }} />
            </figure>
          </div>
          <form onSubmit={onSubmit}>
            <div className="card-body d-flex flex-column align-items-center">
              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Atenção!</strong> {error}
                </div>
              )}
              <h3>
                Você recebeu um código de segurança via e-mail e/ou WhatsApp!
              </h3>
              <p>Enviamos o código para <span>{email}</span></p>
              <div className="row">
                {securityCode.map((code, index) => (
                  <div className="col-3" key={index}>
                    <input
                      ref={input => {
                        if (input) inputRefs.current[index] = input
                      }}
                      type="text"
                      className="form-control"
                      value={code}
                      onChange={event => handleChange(index, event)}
                      onKeyDown={event => handleKeyDown(index, event)}
                      onPaste={handlePaste}
                      maxLength={1}
                    />
                  </div>
                ))}
              </div>
              <div className="link-codigo">
                <p>Não recebeu um código? Verifique seu spam ou <Link to="#" onClick={resend}><span>clique para reenviar</span></Link></p>
                <p>Se estiver com dificuldades, nos chame por <Link target="_blank" to={`https://wa.me/5511983180033?text=${encodeURIComponent('Estou com dificuldades para acessar minha conta.')}`}><span>WhatsApp</span></Link></p>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-center pb-3">
              <div className="row">
                <div className="col-6 col-lg-6">
                  <Link className="return-btn btn btn-primary" to="/">
                    <MdKeyboardArrowLeft />
                    Cancelar
                  </Link>
                </div>
                <div className="col-6 col-lg-6">
                  <button type="submit" className="register-btn btn btn-primary" disabled={isLoading || isLoadingResend}>
                    Verificar
                    <MdKeyboardArrowRight />
                    <LoginSuccessModal donorId={donorId} showModal={showSuccessModal} handleCloseModal={handleCloseSuccessModal} />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SecurityCode
