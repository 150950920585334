import React, { useContext, useEffect, useState } from 'react'
import '@resources/fontes/fontes.css'
import '@resources/css/donators.css'
import AuthContext from '@contexts/Auth'
import { PhoneInput } from 'react-international-phone'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Mask from '@helpers/Mask'
import { cpfIsValid } from '@helpers/Utils'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const animatedComponents = makeAnimated()

type Props = {
  countries: any
  countriesWithBrNewMask: any
}

const ProfilePatient: React.FC<Props> = ({ countries, countriesWithBrNewMask }) => {
  const { user } = useContext(AuthContext)

  const [ data, setData ] = useState<any>({
    name: '',
    nationality: '',
    document_type: '',
    document: '',
    whatsapp: '',
    email: '',
  })

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (user) {
      setData({
        name: user.name,
        nationality: user.patient.nationality,
        document_type: user.document_type,
        document: user.document,
        whatsapp: user.whatsapp,
        email: user.email,
      })
    }
  }, [user])

  if (!user) {
    return null
  }

  const handleUserDocumentType = (e: any) => {
    if (!user) return

    let document = user.document

    if (document) {
      document = document.replace(/\D/g, '')
      if (e.value == 'cpf') {
        document = Mask.cpf(document.substring(0, 11))
      }
    }

    setData({
      ...data,
      document_type: e.value,
      document
    })
  }

  const handleDocumentChange = (e: any) => {
    if (!user) return

    let value = e.target.value

    if (user.document_type === 'cpf') {
      value = Mask.cpf(e.target.value)

      if (value.length > 14) {
        value = value.slice(0, 14)
      }

      if (value.length === 14 && !cpfIsValid(value)) {
        value = value.slice(0, 13)

        SweetAlert.fire({
          title: 'Erro!',
          text: 'CPF inválido',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }

    setData({ ...data, document: value })
  }

  return (
    <div className="row">
      <div className="col-12 col-sm-6 col-md-8">
        <div className="form-group">
          <label>Nome e sobrenome <span>*</span></label>
          <input
            type="text"
            placeholder=""
            className={`form-control ${!data.name ? 'is-invalid' : ''}`}
            name="name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            required
          />
        </div>
      </div>

      <div className="col-12 col-sm-6 col-md-4">
        <div className="form-group">
          <label>Nacionalidade <span>*</span></label>
          <Select
            className={`react-select ${!data.nationality ? 'is-invalid' : ''}`}
            placeholder=""
            id="nationalityValue"
            name="nationality"
            components={animatedComponents}
            options={countries.map((country: any) => ({ value: country.label, label: country.label }))}
            value={data.nationality ? { value: data.nationality, label: data.nationality } : null}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={false}
            onChange={(e: any) => setData({ ...data, nationality: e.value })}
            closeMenuOnSelect={true}
            menuPosition="fixed"
            required
          />
        </div>
      </div>

      <div className="form-group col-12 col-sm-4 col-md-4">
        <label>CPF / Passaporte *:</label>
        <div className="row m-0">
          <div className="col-md-4 p-0">
            <Select
              className={`react-select ${!data.document ? 'is-invalid' : ''}`}
              placeholder=""
              id="document_type"
              name="document_type"
              isSearchable={false}
              isClearable={false}
              options={[{ value: 'cpf', label: 'CPF' }, { value: 'passport', label: 'Passaporte' }]}
              value={data.document_type === 'passport' ? { value: 'passport', label: 'Passaporte' } : { value: 'cpf', label: 'CPF' }}
              onChange={(e) => { handleUserDocumentType(e) }}
              required
            />
          </div>
          <div className="col-md-8 p-0 mt-3 mt-md-0 pl-md-3">
            <input
              id="document"
              name="document"
              className={`form-control ${!data.document ? 'is-invalid' : ''}`}
              value={data.document ?? ''}
              onChange={(e) => handleDocumentChange(e)}
              required
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-4 col-md-4">
        <div className="form-group">
          <label>Whatsapp <span>*</span></label>
          <PhoneInput
            defaultCountry="br"
            countries={countriesWithBrNewMask}
            inputClassName="w-100"
            name="whatsapp"
            value={data.whatsapp ?? ''}
            onChange={(whatsapp) => setData({ ...data, whatsapp })}
            required
          />
        </div>
      </div>
      <div className="col-12 col-sm-4 col-md-4">
        <div className="form-group">
          <label>E-mail <span>*</span></label>
          <input
            type="text"
            placeholder=""
            className={`form-control ${!data.email ? 'is-invalid' : ''}`}
            name="email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            required
          />
        </div>
      </div>
    </div>
  )
}

export default ProfilePatient
