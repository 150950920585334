import FloatWhatsappButton from '@components/FloatWhatsappButton/FloatWhatsappButton'
import PanelContext from '@contexts/Panel'
import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import ReactGA from 'react-ga4'

const GA_TRACKING_ID = 'G-VN68N0JE2R'

const PROD_URL = 'https://portal.proseed.com.br'

const App: React.FC<any> = () => {
  const { collapsed } = useContext(PanelContext)

  useEffect(() => {
    const isProd = window.location.origin === PROD_URL

    if (isProd) {
      console.log('GA initialized')
      ReactGA.initialize(GA_TRACKING_ID)
    }
  }, [])

  return (
    <>
      <div className={'main-wrapper' + (collapsed ? ' sidebar-mini' : '')}>
        <div className="main-content">
          <Outlet />
        </div>

        <FloatWhatsappButton
          whatsappNumber="5511983180033"
          text="Gostaria de reservar um doador."
          cta="Faça sua reserva!"
        />
      </div>
    </>
  )
}

export default App
