import '@resources/fontes/fontes.css'
import '@resources/css/profile.css'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'swiper/css/navigation'
import Header from '@components/Header/Header'
import Sidebar from '@components/Sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import AuthContext from '@contexts/Auth'
import { buildCountryData, defaultCountries, parseCountry, PhoneInput } from 'react-international-phone'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Ibge from '@services/Ibge'
import axios from 'axios'
import Mask from '@helpers/Mask'
import { cpfIsValid } from '@helpers/Utils'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Button } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import ProfilePatient from '@components/ProfilePatient/ProfilePatient'
import ProfileDoctor from '@components/ProfileDoctor/ProfileDoctor'
import * as $Profile from '@services/Profile'
import { htmlErrorMessage } from '@helpers/Validation'

const animatedComponents = makeAnimated()

const Profile: React.FC<any> = () => {
  const { user, setUser, logout } = useContext(AuthContext)

  const [ hasPartner, setHasPartner ] = useState(false)
  const [ countries, setCountries ] = useState<any>([])
  const [ states, setStates ] = useState<any>([])

  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const [ passwordData, setPasswordData ] = useState<any>({
    current_password: '',
    password: '',
    password_confirmation: '',
  })

  const [ addressData, setAddressData ] = useState<any>({
    country: '',
    zipcode: '',
    street: '',
    number: '',
    complement: '',
    area: '',
    city: '',
    state: '',
  })

  const [ partnerData, setPartnerData ] = useState<any>({
    partner_name: '',
    partner_document_type: 'cpf',
    partner_document: '',
    partner_phone: '',
    partner_email: '',
  })

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (user && user.patient) {
      setAddressData({
        country: user.patient.country,
        zipcode: user.patient.zipcode,
        street: user.patient.street,
        number: user.patient.number,
        complement: user.patient.complement,
        area: user.patient.area,
        city: user.patient.city,
        state: user.patient.state,
      })

      setPartnerData({
        partner_name: user.patient.partner_name,
        partner_document_type: user.patient.partner_document_type,
        partner_document: user.patient.partner_document,
        partner_phone: user.patient.partner_phone,
        partner_email: user.patient.partner_email,
      })

      setHasPartner(user.patient.partner_name ? true : false)
    }
  }, [user])

  useEffect(() => {
    const $requests = [
      $Ibge.countries(),
      $Ibge.states()
    ]

    axios.all($requests).then(axios.spread(({data: countries}, {data: states}) => {
      setCountries(countries.map(([ country, name ]: string[]) => ({ value: country, label: name })))
      setStates(
        Object.values(states)
          .sort((a: any, b: any) => a.sigla.localeCompare(b.sigla))
          .map((state: any) => ({ value: state.sigla, label: state.sigla }))
      )
    }))
  }, [])

  const countriesWithBrNewMask = defaultCountries.map((country: any) => {
    const parsedCountry = parseCountry(country)

    if (parsedCountry.name === 'Brazil') {
      parsedCountry.format = '(..) .....-....'
    }

    return buildCountryData(parsedCountry)
  })

  const handleCEP = (input: any) => {
    if (!user) return
    const cep = input.value.replace(/\D/g, '')

    if (input.value.length === 9){
      setUser({
        ...user,
        patient: {
          ...user.patient,
          zipcode: cep,
          street: 'carregando...',
          area: 'carregando...',
          city: 'carregando...',
          state: 'carregando...'
        }
      })

      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response.json())
        .then(data => {
          setUser({
            ...user,
            patient: {
              ...user.patient,
              zipcode: cep,
              street: data.logradouro != undefined ? data.logradouro : '',
              area: data.bairro != undefined ? data.bairro : '',
              city: data.localidade != undefined ? data.localidade : '',
              state: data.uf != undefined ? data.uf : ''
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setUser({
        ...user,
        patient: {
          ...user.patient,
          zipcode: cep
        }
      })
    }
  }

  const handlePartnerDocumentType = (e: any) => {
    if (!user) return

    let partner_document = partnerData.partner_document

    if (partner_document) {
      partner_document = partner_document.replace(/\D/g, '')
      if (e.value == 'cpf') {
        partner_document = Mask.cpf(partner_document.substring(0, 11))
      }

      setPartnerData({
        ...partnerData,
        partner_document_type: e.value,
        partner_document
      })
    }
  }

  const handlePartnerDocumentChange = (e: any) => {
    if (!user) return

    let value = e.target.value

    if (partnerData.partner_document === value) {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'O CPF do parceiro não pode ser igual ao da receptora',
        icon: 'error',
        confirmButtonText: 'Ok',
      })

      setPartnerData({ ...partnerData, partner_document: '' })

      return
    }

    if (partnerData.partner_document_type === 'cpf') {
      value = Mask.cpf(e.target.value)

      if (value.length > 14) {
        value = value.slice(0, 14)
      }

      if (value.length === 14 && !cpfIsValid(value)) {
        value = value.slice(0, 13)

        SweetAlert.fire({
          title: 'Erro!',
          text: 'CPF inválido',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }

    setPartnerData({ ...partnerData, partner_document: value })
  }

  const handleHasPartner = () => {
    if (!user) return

    setHasPartner(prev => !prev)

    if (!hasPartner) {
      setPartnerData({
        partner_name: '',
        partner_document_type: 'cpf',
        partner_document: '',
        partner_phone: '',
        partner_email: '',
      })
    }
  }

  const toggleCurrentPasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowCurrentPassword(!showCurrentPassword)
  }

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  const togglePasswordConfirmationVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const patientIsNational = addressData.country === 'Brasil'
  const userIsPatient = user?.role === 'Paciente'

  const handleProfileUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando perfil',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(e.currentTarget)

    const service = userIsPatient ? $Profile.personalPatient : $Profile.personalDoctor

    service(formData).then(({ data: user }: any) => {
      setUser(user)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Perfil atualizado com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
    }).catch((e: any) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, e.response.data.message || 'Não foi possível atualizar o perfil'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handlePasswordUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando senha',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(e.currentTarget)

    $Profile.password(formData).then(({ data: user }: any) => {
      setUser(user)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Senha atualizada com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
    }).catch((e: any) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, e.response.data.message || 'Não foi possível atualizar a senha'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handleAddressUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando endereço',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(e.currentTarget)

    $Profile.address(formData).then(({ data: user }: any) => {
      setUser(user)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Endereço atualizado com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
    }).catch((e: any) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, e.response.data.message || 'Não foi possível atualizar o endereço'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handlePartnerUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Atualizando dados do parceiro(a)',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    const formData = new FormData(e.currentTarget)

    $Profile.partner(formData).then(({ data: user }: any) => {
      setUser(user)

      SweetAlert.fire({
        title: 'Sucesso!',
        text: 'Dados do parceiro(a) atualizados com sucesso',
        icon: 'success',
        confirmButtonText: 'Ok',
      })
    }).catch((e: any) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, e.response.data.message || 'Não foi possível atualizar os dados do parceiro(a)'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handleExcludeRequest = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Tem certeza que deseja solicitar a exclusão de seus dados?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        SweetAlert.fire({
          title: 'Aguarde...',
          text: 'Solicitando exclusão de dados',
          icon: 'info',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => SweetAlert.showLoading(),
        })

        $Profile.excludeRequest().then(() => {
          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Solicitação de exclusão de dados realizada com sucesso',
            icon: 'success',
            confirmButtonText: 'Ok',
          }).then(() => logout())
        }).catch((e: any) => {
          SweetAlert.fire({
            title: 'Erro!',
            html: htmlErrorMessage(e.response.data, e.response.data.message || 'Não foi possível solicitar a exclusão de dados'),
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
      }
    })
  }

  return (
    <div className="profile-page">
      <Sidebar />
      <main className="with-fixed-nav px-4 pl-xl-0 pr-xl-5">
        <div className="row align-items-center justify-content-between mb-3">
          <div className="col-7 col-sm-7 col-md-3 d-none d-md-block">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Catálogo
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Perfil
                </a>
              </div>
            </div>
          </div>
          <div className="col-7 col-sm-7 col-md-3 pl-5">
            <img src={LogoProssed} alt="" className="img-fluid d-block d-md-none" />
          </div>
          <div className="col-5 col-sm-5 col-md-6">
            <Header />
          </div>
        </div>
        <div className="pt-2 pb-2 d-block d-md-none">
          <div className="row m-0">
            <div className="col-auto pr-0">
              <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                Catálogo
              </a>
            </div>
            <div className="col-auto pl-1 pr-1">
              <BsChevronRight size={10} />
            </div>
            <div className="col-auto p-0">
              <a href="#" className="id d-flex align-items-center font-weight-bold">
                Perfil
              </a>
            </div>
          </div>
        </div>

        {user ? (
          <>
            <section className="form-section">
              <form onSubmit={handleProfileUpdate}>
                <div className="form-line">
                  <div className="text">
                    <h5>Perfil</h5>
                    <p>Aqui estão os principais dados de acesso do seu perfil</p>
                  </div>

                  {userIsPatient ? (
                    <ProfilePatient countries={countries} countriesWithBrNewMask={countriesWithBrNewMask} />
                  ) : (
                    <ProfileDoctor countriesWithBrNewMask={countriesWithBrNewMask} />
                  )}
                </div>

                <div className="d-flex align-items-center justify-content-end">
                  <div className="profile-btn-div d-flex align-items-center">
                    <button type="submit" className="save-btn btn btn-primary">Salvar</button>
                  </div>
                </div>
              </form>
            </section>

            <section className="form-section">
              <form onSubmit={handlePasswordUpdate}>
                <div className="form-line">
                  <div className="text">
                    <h5>Alteração de Senha</h5>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4">
                      <div className="form-group">
                        <label>Senha Atual <span>*</span></label>

                        <div className="inputs d-flex justify-content-between">
                          <input
                            type={showCurrentPassword ? 'text' : 'password'}
                            placeholder=""
                            className={'form-control mb-3 custom-placeholder' + (!passwordData.current_password ? ' is-invalid' : '')}
                            name="current_password"
                            value={passwordData.current_password}
                            onChange={(e) => setPasswordData({ ...passwordData, current_password: e.target.value })}
                            required
                          />
                          <button
                            type="button"
                            className={`btn eye-icon px-3 ${!passwordData.current_password ? 'mr-3' : ''}`}
                            onClick={(e) => toggleCurrentPasswordVisibility(e)}
                          >
                            {showCurrentPassword ? (
                              <FaEyeSlash className="closed-eye-icon text-dark" />
                            ) : (
                              <FaEye className="open-eye-icon text-dark" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4">
                      <div className="form-group">
                        <label>Nova Senha <span>*</span></label>

                        <div className="inputs d-flex justify-content-between">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder=""
                            className={'form-control mb-3 custom-placeholder' + (!passwordData.password ? ' is-invalid' : '')}
                            name="password"
                            value={passwordData.password}
                            onChange={(e) => setPasswordData({ ...passwordData, password: e.target.value })}
                            required
                          />
                          <button
                            type="button"
                            className={`btn eye-icon px-3 ${!passwordData.password ? 'mr-3' : ''}`}
                            onClick={(e) => togglePasswordVisibility(e)}
                          >
                            {showPassword ? (
                              <FaEyeSlash className="closed-eye-icon text-dark" />
                            ) : (
                              <FaEye className="open-eye-icon text-dark" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-4">
                      <div className="form-group">
                        <label>Confirme sua senha <span>*</span></label>

                        <div className="inputs d-flex justify-content-between">
                          <input
                            type={showPasswordConfirmation ? 'text' : 'password'}
                            placeholder=""
                            className={'form-control mb-3 custom-placeholder' + (!passwordData.password_confirmation ? ' is-invalid' : '')}
                            name="password_confirmation"
                            value={passwordData.password_confirmation}
                            onChange={(e) => setPasswordData({ ...passwordData, password_confirmation: e.target.value })}
                            required
                          />
                          <button
                            type="button"
                            className={`btn eye-icon px-3 ${!passwordData.password_confirmation ? 'mr-3' : ''}`}
                            onClick={(e) => togglePasswordConfirmationVisibility(e)}
                          >
                            {showPasswordConfirmation ? (
                              <FaEyeSlash className="closed-eye-icon text-dark" />
                            ) : (
                              <FaEye className="open-eye-icon text-dark" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-end">
                  <div className="profile-btn-div d-flex align-items-center">
                    <button type="submit" className="save-btn btn btn-primary">Salvar</button>
                  </div>
                </div>
              </form>
            </section>

            {userIsPatient ? (
              <section className="form-section">
                <form onSubmit={handleAddressUpdate}>
                  <div className="form-line">
                    <div className="text">
                      <h5>Endereço</h5>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label>País <span>*</span></label>
                          <Select
                            className={`react-select ${!addressData.country ? 'is-invalid' : ''}`}
                            placeholder=""
                            id="countryValue"
                            name="country"
                            components={animatedComponents}
                            options={countries.map((country: any) => ({ value: country.label, label: country.label }))}
                            value={addressData.country ? { value: addressData.country, label: addressData.country } : null}
                            isSearchable={true}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={true}
                            isRtl={false}
                            onChange={(e: any) => { setAddressData({ ...addressData, country: e.value }) }}
                            closeMenuOnSelect={true}
                            menuPosition="fixed"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-2">
                        <div className="form-group">
                          <label>CEP *:</label>
                          {patientIsNational ? (
                            <InputMask
                              className={`form-control ${!addressData.zipcode || addressData.zipcode.length < 8 ? 'is-invalid' : ''}`}
                              mask="99999-999"
                              name="zipcode"
                              value={addressData.zipcode ?? ''}
                              maskChar={null}
                              autoComplete="off"
                              onChange={(e) => setAddressData({ ...addressData, zipcode: e.target.value })}
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder=""
                              className={`form-control ${!addressData.zipcode ? 'is-invalid' : ''}`}
                              name="zipcode"
                              value={addressData.zipcode ?? ''}
                              onChange={(e) => setAddressData({ ...addressData, zipcode: e.target.value })}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 d-flex align-items-center">
                        {patientIsNational && (
                          <Button type="button" className="cep-btn btn btn-primary mb-xl-0" onClick={() => handleCEP({ value: addressData.zipcode })}>
                            Buscar Endereço
                          </Button>
                        )}
                      </div>
                      <div className="col-12"></div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="form-group">
                          <label>Endereço <span>*</span></label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control ${!addressData.street ? 'is-invalid' : ''}`}
                            name="street"
                            value={addressData.street}
                            onChange={(e) => setAddressData({ ...addressData, street: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label>Número <span>*</span></label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control ${!addressData.number ? 'is-invalid' : ''}`}
                            name="number"
                            value={addressData.number}
                            onChange={(e) => setAddressData({ ...addressData, number: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <div className="form-group">
                          <label>Complemento</label>
                          <input
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="complement"
                            value={addressData.complement}
                            onChange={(e) => setAddressData({ ...addressData, complement: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-4">
                        <div className="form-group">
                          <label>Bairro {patientIsNational && <span>*</span>}</label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control ${patientIsNational && !addressData.area ? 'is-invalid' : ''}`}
                            name="area"
                            value={addressData.area}
                            onChange={(e) => setAddressData({ ...addressData, area: e.target.value })}
                            required={patientIsNational}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label>Cidade <span>*</span></label>
                          <input
                            type="text"
                            placeholder=""
                            className={`form-control ${!addressData.city ? 'is-invalid' : ''}`}
                            name="city"
                            value={addressData.city}
                            onChange={(e) => setAddressData({ ...addressData, city: e.target.value })}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label>Estado <span>*</span></label>
                          {patientIsNational ? (
                            <Select
                              className={`react-select ${!addressData.state ? 'is-invalid' : ''}`}
                              placeholder=""
                              id="stateValue"
                              name="state"
                              components={animatedComponents}
                              options={states.map((state: any) => ({ value: state.label, label: state.label }))}
                              value={addressData.state ? { value: addressData.state, label: addressData.state } : null}
                              isSearchable={true}
                              isDisabled={false}
                              isLoading={false}
                              isRtl={false}
                              onChange={(e: any) => { setAddressData({ ...addressData, state: e.value }) }}
                              closeMenuOnSelect={true}
                              menuPosition="fixed"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder=""
                              className={`form-control ${!addressData.state ? 'is-invalid' : ''}`}
                              name="state"
                              value={addressData.state}
                              onChange={(e) => setAddressData({ ...addressData, state: e.target.value })}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-end">
                    <div className="profile-btn-div d-flex align-items-center">
                      <button type="submit" className="save-btn btn btn-primary">Salvar</button>
                    </div>
                  </div>
                </form>
              </section>
            ) : null}

            {userIsPatient ? (
              <section className="form-section">
                <form onSubmit={handlePartnerUpdate}>
                  <div className="form-line d-flex my-3">
                    <div className="custom-control custom-switch">
                      <input
                        className="custom-control-input d-flex align-items-center"
                        type="checkbox"
                        role="switch"
                        onChange={handleHasPartner}
                        checked={hasPartner}
                        id="has_partner"
                      />
                      <label className="custom-control-label" htmlFor="has_partner"></label>
                    </div>
                    <h6>Tem companheiro(a) ou cônjuge?</h6>
                  </div>

                  {hasPartner && (
                    <>
                      <div className="form-line">
                        <div className="text">
                          <h5>Dados do Companheiro</h5>
                        </div>
                      </div>

                      <div className="form-line">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="form-group">
                              <label>Nome e sobrenome <span>*</span></label>
                              <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                name="partner_name"
                                value={partnerData.partner_name}
                                onChange={(e) => setPartnerData({ ...partnerData, partner_name: e.target.value })}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Celular <span>*</span></label>
                              <PhoneInput
                                defaultCountry="br"
                                countries={countriesWithBrNewMask}
                                inputClassName="w-100"
                                name="partner_phone"
                                value={partnerData.partner_phone ?? ''}
                                onChange={(e: any) => setPartnerData({ ...partnerData, partner_phone: e })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-line">
                        <div className="row">
                          <div className="col-12 col-md-6 form-group mb-3">
                            <label>CPF / Passaporte:</label>
                            <div className="row m-0">
                              <div className="col-md-4 p-0">
                                <Select
                                  className="react-select"
                                  placeholder=""
                                  id="partner_document_type"
                                  name="partner_document_type"
                                  isSearchable={false}
                                  isClearable={false}
                                  options={[{ value: 'cpf', label: 'CPF' }, { value: 'passport', label: 'Passaporte' }]}
                                  value={partnerData.partner_document_type === 'passport' ? { value: 'passport', label: 'Passaporte' } : { value: 'cpf', label: 'CPF' }}
                                  onChange={(e) => { handlePartnerDocumentType(e) }}
                                />
                              </div>
                              <div className="col-md-8 p-0 pl-3">
                                <input
                                  id="partner_document"
                                  name="partner_document"
                                  className="form-control"
                                  value={partnerData.partner_document ?? ''}
                                  onChange={(e) => handlePartnerDocumentChange(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>E-mail <span>*</span></label>
                              <input
                                type="text"
                                placeholder=""
                                className="form-control"
                                name="partner_email"
                                value={partnerData.partner_email}
                                onChange={(e) => setPartnerData({ ...partnerData, partner_email: e.target.value })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="d-flex align-items-center justify-content-end">
                    <div className="profile-btn-div d-flex align-items-center">
                      <button type="submit" className="save-btn btn btn-primary">Salvar</button>
                    </div>
                  </div>
                </form>
              </section>
            ) : null}

            <section className="form-section">
              <form onSubmit={handleExcludeRequest}>
                <div className="form-line">
                  <div className="text">
                    <h5>Política de Privacidade</h5>
                    <p className="mb-0">Em atendimento ao disposto no Artigo 18 da LGPD, o usuário pode solicitar a exclusão de seus dados pessoais</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row gap-15">
                    <div>
                      <Link to="https://proseed.com.br/termos-de-uso" target="_blank"> Termos de Uso</Link> |
                      <Link to="https://proseed.com.br/politica-de-privacidade" target="_blank"> Política de privacidade</Link>
                    </div>
                    <div className="profile-btn-div d-flex align-items-center">
                      <button type="submit" className="save-btn btn btn-danger">Solicitar Exclusão</button>
                    </div>
                  </div>
                </div>
              </form>
            </section>
          </>
        ) : null}
      </main>
    </div>
  )
}

export default Profile
