import React, { useContext, useEffect, useState } from 'react'
import '@resources/fontes/fontes.css'
import '@resources/css/donators.css'
import AuthContext from '@contexts/Auth'
import { PhoneInput } from 'react-international-phone'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { USER_DOCTOR_TYPES } from '@constants/userDoctorTypes'

const animatedComponents = makeAnimated()

type Props = {
  countriesWithBrNewMask: any
}

const ProfileDoctor: React.FC<Props> = ({ countriesWithBrNewMask }) => {
  const { user } = useContext(AuthContext)

  const [ data, setData ] = useState<any>({
    name: '',
    whatsapp: '',
    email: '',
    doctor_type: '',
    clinic_name: '',
  })

  useEffect(() => {
    if (user) {
      setData({
        name: user.name,
        whatsapp: user.whatsapp,
        email: user.email,
        doctor_type: user.doctor_type,
        clinic_name: user.clinic_name,
      })
    }
  }, [user])

  if (!user) {
    return null
  }

  return (
    <div className="row">
      <div className="col-12 col-sm-6 col-md-8">
        <div className="form-group">
          <label>Nome e sobrenome <span>*</span></label>
          <input
            type="text"
            placeholder=""
            className={`form-control ${!data.name ? 'is-invalid' : ''}`}
            name="name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            required
          />
        </div>
      </div>

      <div className="col-12 col-sm-4 col-md-4">
        <div className="form-group">
          <label>Whatsapp <span>*</span></label>
          <PhoneInput
            defaultCountry="br"
            countries={countriesWithBrNewMask}
            inputClassName="w-100"
            name="whatsapp"
            value={data.whatsapp ?? ''}
            onChange={(whatsapp) => setData({ ...data, whatsapp })}
            required
          />
        </div>
      </div>

      <div className="col-12 col-sm-4 col-md-4">
        <div className="form-group">
          <label>E-mail <span>*</span></label>
          <input
            type="text"
            placeholder=""
            className={`form-control ${!data.email ? 'is-invalid' : ''}`}
            name="email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            required
          />
        </div>
      </div>

      <div className="col-12 col-sm-6 col-mb-6 col-lg-4">
        <div className="form-group">
          <label>Perfil <span>*</span></label>
          <Select
            name="doctor_type"
            className={`react-select ${!data.doctor_type ? 'is-invalid' : ''}`}
            placeholder={<div>Selecione</div>}
            components={animatedComponents}
            options={USER_DOCTOR_TYPES}
            isClearable={false}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            isRtl={false}
            closeMenuOnSelect={true}
            value={data.doctor_type ? { value: data.doctor_type, label: data.doctor_type } : null}
            onChange={(e: any) => { setData({ ...data, doctor_type: e.label }) }}
            required
          />
        </div>
      </div>

      <div className="col-12 col-sm-6 col-mb-6 col-lg-4">
        <div className="form-group">
          <label>Nome da Clínica</label>
          <input
            type="text"
            placeholder=""
            className="form-control"
            name="clinic_name"
            value={data.clinic_name ?? ''}
            onChange={(e) => setData({ ...data, clinic_name: e.target.value })}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileDoctor
