import React, { forwardRef, useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap'
import Photo from '@resources/imgs/mask-group-2.png'
import { useNavigate, useParams } from 'react-router-dom'
import History from './Tabs/History'
import Features from './Tabs/Features'
import General from './Tabs/General'
import Ask from './Tabs/Ask'
import Exams from './Tabs/Exams'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import '@resources/css/donator.css'
import CardSugestion from './../../components/SwiperSlide/CardSugestion'
import Cart from '@resources/imgs/cart.png'
import Favoritos from '@resources/imgs/favoritos.png'
import Printer from '@resources/imgs/printer.png'
import Share from '@resources/imgs/share.png'
import Header from '@components/Header/Header'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import * as $Donor from '@services/Donor'
import IDonor from '@interfaces/IDonor'
import LogoEuropean from '@resources/imgs/european.webp'
import LogoSeattle from '@resources/imgs/seattle.png'
import Ovulos from '@resources/imgs/tratament-2.png'
import Embriao from '@resources/imgs/tratament-1.png'
import Trompas from '@resources/imgs/tratament-3.png'
import { BsChevronRight } from 'react-icons/bs'
import New from '@resources/imgs/icon-new.svg'
import IDonorSuggestion from '@interfaces/IDonorSuggestion'
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner'
import CatalogFooter from '@components/CatalogFooter/CatalogFooter'

const Action = forwardRef(({ children, onClick, }: any, ref: any) => (
  <Button
    ref={ref}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >{children}</Button>
))

Action.displayName = 'action'

const Donator: React.FC<any> = () => {
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState(true)
  const [donor, setDonor] = useState<IDonor>({} as IDonor)
  const [donorSuggestions, setDonorSuggestions] = useState<IDonorSuggestion[]>([])

  const [bannerId, setBannerId] = useState(10)

  useEffect(() => {
    const randomId = Math.floor(Math.random() * (18 - 10 + 1)) + 10
    setBannerId(randomId)
  }, [])

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    if (id) {
      setIsLoading(true)

      const params = {
        params: {
          include_suggestions: true
        }
      }

      $Donor.find(id, params).then(({ data: donor }) => {
        setDonor(donor)

        if (donor.suggestions) {
          setDonorSuggestions(donor.suggestions)
        }
      }).catch((error) => {
        let message = error.message || 'Erro ao buscar doador'

        if (error.response.status === 404) {
          message = 'Doador não encontrado'
        }

        SweetAlert.fire('Erro', message, 'error')

        navigate('/')
      }).finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const [mostrarSearchDonator, setMostrarSearchDonator] = useState(false)

  const handleBtnCartClick = () => {
    setMostrarSearchDonator(!mostrarSearchDonator)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      },
    ]
  }

  return (
    <>
      <div className="donator-page">
        <LoadingSpinner isLoading={isLoading} text="Buscando doador..." />

        <div className="container">
          <div className="row m-0 align-items-center justify-content-between">
            <div className="col-6 col-sm-6 col-md-3">
              <img src={LogoProssed} alt="" className="img-fluid" />
            </div>
            <div className="col-6 col-sm-6 col-md-3">
              <Header />
            </div>
          </div>
          <div className="py-4">
            <div className="row m-0">
              <div className="col-auto pr-0">
                <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
                  Catálogo
                </a>
              </div>
              <div className="col-auto pl-1 pr-1">
                <BsChevronRight size={10} />
              </div>
              <div className="col-auto p-0">
                <a href="#" className="id d-flex align-items-center font-weight-bold">
                  Doador
                </a>
              </div>
              <div className="col-6">
                <div className="d-flex flex-column align-items-start">
                  <div className={`search-donator ${mostrarSearchDonator ? 'd-block' : 'd-none'}`}>
                  Buscar doador / <span>Doador {donor.internal_id}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-lg-7">
              <div className="user-box">
                <figure className="banner mb-0">
                  <img src={`https://picsum.photos/id/${bannerId}/770/225`} alt="" />
                </figure>
                <div className="profile-details">
                  <figure className="img-user">
                    <img src={donor.avatar || Photo} alt="" />
                    {donor?.is_new_donor ? (
                      <div className="icon-new">
                        <img src={New} alt="" />
                      </div>
                    ) : null}
                  </figure>
                  <div className="user-info-id">
                    <div className="donator-details">
                      <h5>
                      ID: {donor.internal_id}
                      </h5>
                      <p>
                        {donor.age && (
                          `${donor.age} anos`
                        )}
                        {donor.nickname && donor.sperm_bank?.name !== 'Pro-Seed' && (
                          <>
                            <br />
                            {donor.nickname}
                          </>
                        )}
                        {donor.donor_type && donor.sperm_bank?.name !== 'Pro-Seed' && (
                          <>
                            <br />
                            {donor.donor_type}
                          </>
                        )}
                        <br />
                        <>{donor.sperm_bank?.name === 'Pro-Seed' ? 'Amostra Nacional' : 'Amostra Internacional'}</>
                      </p>
                    </div>
                    <div className="bank mr-5">
                      <figure>
                        {donor.sperm_bank?.name === 'Pro-Seed' ? (
                          <img src={LogoProssed} alt="" width={150} />
                        ) : donor.sperm_bank?.name === 'European Sperm Bank' ? (
                          <img src={LogoEuropean} alt="" width={150} />
                        ) : donor.sperm_bank?.name === 'Seattle Sperm Bank' ? (
                          <img src={LogoSeattle} alt="" width={150} />
                        ) : (
                          <img src={LogoProssed} alt="" width={150} />
                        )}
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="details">
                <div className="line-content">
                  <div className="content d-flex align-items-center justify-content-between">
                    <div className="result w-100">
                      <div className="title">
                        Amostras disponíveis para tratamento de:
                      </div>
                      {donor?.available_treatment_names && donor?.available_treatment_names?.length > 0 ? (
                        <div className="tratamentos d-flex pt-2 w-100">
                          {donor?.available_treatment_names.includes('ICSI') && donor.icsi_price ? (
                            <div className="d-flex col-4 p-0 flex-column align-items-center justify-content-center">
                              <div className="result d-flex align-items-center justify-content-center gap-5">
                                <img src={Ovulos} alt="" />
                                <span>ICSI</span>
                              </div>
                              <span className="treatment-values">R$ {donor.icsi_price}</span>
                            </div>
                          ) : null}
                          {donor?.available_treatment_names.includes('FIV') && donor.fiv_price ? (
                            <div className="d-flex col-4 p-0 flex-column align-items-center justify-content-center">
                              <div className="result d-flex align-items-center justify-content-center gap-5">
                                <img src={Embriao} alt="" />
                                <span>FIV</span>
                              </div>
                              <span className="treatment-values">R$ {donor.fiv_price}</span>
                            </div>
                          ) : null}
                          {donor?.available_treatment_names.includes('IIU') && donor.iiu_price ? (
                            <div className="d-flex col-4 p-0 flex-column align-items-center justify-content-center">
                              <div className="result d-flex align-items-center justify-content-center gap-5">
                                <img src={Trompas} alt="" />
                                <span>Inseminação</span>
                              </div>
                              <span className="treatment-values">R$ {donor.iiu_price}</span>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="tratamentos d-flex pt-2">
                          <div className="result d-flex px-1">
                            <span>Não disponível</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="line-content d-flex text-center justify-content-around">
                  <div className="content features">
                    <div className="title">
                    Cor da pele
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      <span>{donor?.skin_color?.name}</span>
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Cor dos olhos
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.eye_color?.name}
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Cor do Cabelo
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.hair_color?.name}
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Raça
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.race?.name}
                    </div>
                  </div>
                </div>
                <div className="line-content d-flex text-center justify-content-around">
                  <div className="content features">
                    <div className="title text-center">
                    Tipo Sanguíneo
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.blood_group?.name}
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Altura
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.height}
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Peso
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                      {donor?.weight}Kg
                    </div>
                  </div>
                  <div className="content features">
                    <div className="title">
                    Disponibilidade
                    </div>
                    <div className="result d-flex justify-content-center align-items-center">
                    Envio imediato
                    </div>
                  </div>
                </div>
                <div className="line-content d-none">
                  <div className="content btns d-flex justify-content-end">
                    <div className="buttons d-flex justify-content-between">
                      <button className="btn-cart btn btn-primary" onClick={handleBtnCartClick}>
                        <figure>
                          <img className="btn btn-primary" src={Cart} alt="" />
                        </figure>
                      </button>
                      <button className="btn btn-primary">
                        <figure>
                          <img className="cart-icon" src={Favoritos} alt="" />
                        </figure>
                      </button>
                      <button className="btn btn-primary">
                        <figure>
                          <img className="cart-icon" src={Share} alt="" />
                        </figure>
                      </button>
                      <button className="btn btn-primary">
                        <figure>
                          <img className="cart-icon" src={Printer} alt="" />
                        </figure>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {donor.proseed_description ? (
            <div className="donator-impress mb-4">
              <h5>Impressões da equipe do Banco de Sêmen sobre o doador</h5>
              <div className="row mt-3">
                <div className="col-12">
                  <p className="mb-0">
                    {donor.proseed_description}
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div className="info-section">
            <div className="tabs">
              <Tabs
                defaultActiveKey="general"
              >
                <Tab eventKey="general" title="Sobre o Doador">
                  <General donor={donor} />
                </Tab>
                <Tab eventKey="features" title="Características Físicas">
                  <Features donor={donor} />
                </Tab>
                {donor.sperm_bank?.name === 'Pro-Seed' && (
                  <Tab eventKey="history" title="Histórico de Saúde & Família">
                    <History donor={donor} />
                  </Tab>
                )}
                <Tab eventKey="exams" title="Triagem Médica & Exames">
                  <Exams donor={donor} />
                </Tab>
                <Tab eventKey="ask" title="Palavras do Doador">
                  <Ask donor={donor} />
                </Tab>
              </Tabs>
            </div>
          </div>

          {donorSuggestions.length > 0 && (
            <div className="sugestions mb-5">
              <div className="title pb-3">
                <h5>Separamos alguns doadores para você.</h5>
                <p>Confira mais alguns doares que são parecidos com o da sua busca.</p>
              </div>
              <Slider {...settings}>
                {donorSuggestions.map((donorSuggestion: IDonorSuggestion) => (
                  <div key={donorSuggestion.id}>
                    <CardSugestion donor={donorSuggestion} />
                  </div>
                ))}
                {Array.from({ length: donorSuggestions.length }).map((_, index) => (
                  <div key={donorSuggestions[index].id}>
                    <>
                      <CardSugestion donor={donorSuggestions[index]} />
                    </>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
      <CatalogFooter />
    </>
  )
}

export default Donator
