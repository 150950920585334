import React, { useState, ChangeEvent, useEffect, useContext } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import '@resources/css/register.css'
import { RiUserFollowLine } from 'react-icons/ri'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import * as $Ibge from '@services/Ibge'
import IPatient from '@interfaces/IPatient'
import axios from 'axios'
import { PhoneInput, defaultCountries, parseCountry, buildCountryData } from 'react-international-phone'
import 'react-international-phone/style.css'
import InputMask from 'react-input-mask'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import Mask from '@helpers/Mask'
import { cpfIsValid } from '@helpers/Utils'
import * as $Auth from '@services/Auth'
import { htmlErrorMessage } from '@helpers/Validation'
import AuthContext from '@contexts/Auth'
import { BsChevronRight } from 'react-icons/bs'
import LogoProssed from '@resources/imgs/logo-proseed-2-4.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const animatedComponents = makeAnimated()

const whereMeetProseedOptions = [
  { value: 'Indicação da clínica', label: 'Indicação da clínica' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'Pesquisa no Google', label: 'Pesquisa no Google' },
  { value: 'Indicação de Amiga / Grupo de WhatsApp', label: 'Indicação de Amiga / Grupo de WhatsApp' },
  { value: 'Outros', label: 'Outros' },
]

const Register = () => {
  const { setUser, setToken, setIsAuthenticated } = useContext(AuthContext)

  const [ hasPartner, setHasPartner ] = useState(false)
  const [ countries, setCountries ] = useState<any>([])
  const [ states, setStates ] = useState<any>([])

  const [ patient, setPatient ] = useState<IPatient>({
    document_type: 'cpf',
    partner_document_type: 'cpf',
    nationality: 'Brasil',
    country: 'Brasil',
  } as IPatient)

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false)

  const navigate = useNavigate()

  const SweetAlert = withReactContent(Swal)

  useEffect(() => {
    const $requests = [
      $Ibge.countries(),
      $Ibge.states()
    ]

    axios.all($requests).then(axios.spread(({data: countries}, {data: states}) => {
      setCountries(countries.map(([ country, name ]: string[]) => ({ value: country, label: name })))
      setStates(
        Object.values(states)
          .sort((a: any, b: any) => a.sigla.localeCompare(b.sigla))
          .map((state: any) => ({ value: state.sigla, label: state.sigla }))
      )
    }))
  }, [])

  const countriesWithBrNewMask = defaultCountries.map((country: any) => {
    const parsedCountry = parseCountry(country)

    if (parsedCountry.name === 'Brazil') {
      parsedCountry.format = '(..) .....-....'
    }

    return buildCountryData(parsedCountry)
  })

  const handleCEP = (input: any) => {
    const cep = input.value.replace(/\D/g, '')

    if (input.value.length === 9){
      setPatient({
        ...patient,
        zipcode: cep,
        street: 'carregando...',
        area: 'carregando...',
        city: 'carregando...',
        state: 'carregando...'
      })
      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response.json())
        .then(data => {
          setPatient({
            ...patient,
            zipcode: cep,
            street: data.logradouro != undefined ? data.logradouro : '',
            area: data.bairro != undefined ? data.bairro : '',
            city: data.localidade != undefined ? data.localidade : '',
            state: data.uf != undefined ? data.uf : ''
          })
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      setPatient({ ...patient, zipcode: cep })
    }
  }

  const handlePatientDocumentType = (e: any) => {
    let document = patient.document

    if (patient.document) {
      document = document.replace(/\D/g, '')
      if (e.value == 'cpf') {
        document = Mask.cpf(document.substring(0, 11))
      }
    }

    setPatient({
      ...patient,
      document_type: e.value,
      document
    })
  }

  const handlePartnerDocumentType = (e: any) => {
    let partner_document = patient.partner_document

    if (patient.partner_document) {
      partner_document = partner_document.replace(/\D/g, '')
      if (e.value == 'cpf') {
        partner_document = Mask.cpf(partner_document.substring(0, 11))
      }
    }

    setPatient({
      ...patient,
      partner_document_type: e.value,
      partner_document
    })
  }

  const handleDocumentChange = (e: any) => {
    let value = e.target.value

    if (patient.document_type === 'cpf') {
      value = Mask.cpf(e.target.value)

      if (value.length > 14) {
        value = value.slice(0, 14)
      }

      if (value.length === 14 && !cpfIsValid(value)) {
        value = value.slice(0, 13)

        SweetAlert.fire({
          title: 'Erro!',
          text: 'CPF inválido',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }

    setPatient({ ...patient, document: value })
  }

  const handlePartnerDocumentChange = (e: any) => {
    let value = e.target.value

    if (patient.document === value) {
      SweetAlert.fire({
        title: 'Erro!',
        text: 'O CPF do parceiro não pode ser igual ao da receptora',
        icon: 'error',
        confirmButtonText: 'Ok',
      })

      setPatient({ ...patient, partner_document: '' })

      return
    }

    if (patient.partner_document_type === 'cpf') {
      value = Mask.cpf(e.target.value)

      if (value.length > 14) {
        value = value.slice(0, 14)
      }

      if (value.length === 14 && !cpfIsValid(value)) {
        value = value.slice(0, 13)

        SweetAlert.fire({
          title: 'Erro!',
          text: 'CPF inválido',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    }

    setPatient({ ...patient, partner_document: value })
  }

  const handleHasPartner = () => {
    setHasPartner(prev => !prev)

    if (!hasPartner) {
      setPatient({
        ...patient,
        partner_name: '',
        partner_document_type: 'cpf',
        partner_document: '',
        partner_phone: '',
        partner_email: '',
      })
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const registerFullFilled: boolean = (patient.name && patient.nationality && patient.country && patient.document && patient.whatsapp &&
      patient.email && patient.password && patient.password_confirmation && patient.zipcode && patient.street && patient.number &&
      patient.city && patient.state && patient.where_meet_proseed && patient.terms_accepted) ? true : false

    if (!registerFullFilled) {
      const firstInvalidField = [
        'name', 'nationality', 'country', 'document', 'whatsapp', 'email', 'password', 'password_confirmation', 'zipcode', 'street', 'number', 'city', 'state', 'where_meet_proseed', 'terms_accepted'
      ].find(field => !patient[field as keyof IPatient])

      if (firstInvalidField) {
        const fieldElement = document.getElementsByName(firstInvalidField)[0]
        if (fieldElement) {
          fieldElement.focus()
        }
      }

      SweetAlert.fire({
        title: 'Erro!',
        text: 'Por favor, preencha todos os campos obrigatórios.',
        icon: 'error',
        confirmButtonText: 'Ok',
      })

      return
    }

    SweetAlert.fire({
      title: 'Aguarde...',
      text: 'Realizando cadastro',
      icon: 'info',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => SweetAlert.showLoading(),
    })

    $Auth.register(patient).then(({ data: { user, token } }) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      setUser(user)
      setToken(token)
      setIsAuthenticated(true)

      SweetAlert.close()

      navigate('/verificar-email')
    }).catch((e) => {
      SweetAlert.fire({
        title: 'Erro!',
        html: htmlErrorMessage(e.response.data, 'Não foi possível realizar o cadastro'),
        icon: 'error',
        confirmButtonText: 'Ok',
      })
    })
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPatient({ ...patient, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPatient({ ...patient, [e.target.name]: e.target.checked })
  }

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPassword(!showPassword)
  }

  const togglePasswordConfirmationVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    setShowPasswordConfirmation(!showPasswordConfirmation)
  }

  const patientIsNational = patient.country === 'Brasil'

  return (
    <div className="container">
      <div className="row m-0 mt-4 mb-4 align-items-center justify-content-between">
        <div className="col-6 col-sm-6 col-md-3">
          <img src={LogoProssed} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="pt-2 pb-2">
        <div className="row m-0">
          <div className="col-auto pr-0">
            <a href="#" className="text-body d-flex align-items-center" onClick={() => navigate(-1)}>
              Catálogo
            </a>
          </div>
          <div className="col-auto pl-1 pr-1">
            <BsChevronRight size={10} />
          </div>
          <div className="col-auto p-0">
            <a href="#" className="id d-flex align-items-center font-weight-bold">
              Cadastre-se
            </a>
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className="register-section">
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-8">
                <div className="form-group">
                  <label>Nome e sobrenome <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!patient.name ? 'is-invalid' : ''}`}
                    name="name"
                    value={patient.name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="form-group">
                  <label>Nacionalidade <span>*</span></label>
                  <Select
                    className={`react-select ${!patient.nationality ? 'is-invalid' : ''}`}
                    placeholder=""
                    id="nationalityValue"
                    name="nationality"
                    components={animatedComponents}
                    options={countries.map((country: any) => ({ value: country.label, label: country.label }))}
                    value={patient.nationality ? { value: patient.nationality, label: patient.nationality } : null}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    onChange={(e: any) => setPatient({ ...patient, nationality: e?.label ?? null })}
                    closeMenuOnSelect={true}
                    menuPosition="fixed"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="form-group col-12 col-sm-4 col-md-4">
                <label>CPF / Passaporte *:</label>
                <div className="row m-0">
                  <div className="col-md-4 p-0">
                    <Select
                      className={`react-select ${!patient.document ? 'is-invalid' : ''}`}
                      placeholder=""
                      id="document_type"
                      name="document_type"
                      isSearchable={false}
                      isClearable={false}
                      options={[{ value: 'cpf', label: 'CPF' }, { value: 'passport', label: 'Passaporte' }]}
                      value={patient.document_type === 'passport' ? { value: 'passport', label: 'Passaporte' } : { value: 'cpf', label: 'CPF' }}
                      onChange={(e) => { handlePatientDocumentType(e) }}
                    />
                  </div>
                  <div className="col-md-8 p-0 mt-3 mt-md-0 pl-md-3">
                    <input
                      id="document"
                      name="document"
                      className={`form-control ${!patient.document ? 'is-invalid' : ''}`}
                      value={patient.document ?? ''}
                      onChange={(e) => handleDocumentChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Whatsapp <span>*</span></label>
                  <PhoneInput
                    defaultCountry="br"
                    countries={countriesWithBrNewMask}
                    inputClassName="w-100"
                    name="whatsapp"
                    value={patient.whatsapp ?? ''}
                    onChange={(whatsapp) => setPatient({ ...patient, whatsapp })}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>E-mail <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!patient.email ? 'is-invalid' : ''}`}
                    name="email"
                    value={patient.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Senha <span>*</span></label>

                  <div className="inputs d-flex justify-content-between">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={'form-control mb-3 custom-placeholder' + (!patient.password ? ' is-invalid' : '')}
                      name="password"
                      value={patient.password ?? ''}
                      onChange={handleInputChange}
                      required
                    />
                    <button
                      type="button"
                      className={`btn eye-icon px-3 ${!patient.password ? 'mr-3' : ''}`}
                      onClick={(e) => togglePasswordVisibility(e)}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="closed-eye-icon text-dark" />
                      ) : (
                        <FaEye className="open-eye-icon text-dark" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Confirme sua senha <span>*</span></label>

                  <div className="inputs d-flex justify-content-between">
                    <input
                      type={showPasswordConfirmation ? 'text' : 'password'}
                      className={'form-control mb-3 custom-placeholder' + (!patient.password_confirmation ? ' is-invalid' : '')}
                      name="password_confirmation"
                      value={patient.password_confirmation ?? ''}
                      onChange={handleInputChange}
                      required
                    />
                    <button
                      type="button"
                      className={`btn eye-icon px-3 ${!patient.password_confirmation ? 'mr-3' : ''}`}
                      onClick={(e) => togglePasswordConfirmationVisibility(e)}
                    >
                      {showPasswordConfirmation ? (
                        <FaEyeSlash className="closed-eye-icon text-dark" />
                      ) : (
                        <FaEye className="open-eye-icon text-dark" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-line my-4">
            <div className="row">
              <div className="col-12 col-md-12">
                <h6>Qual o seu endereço de residência?</h6>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>País <span>*</span></label>
                  <Select
                    className={`react-select ${!patient.country ? 'is-invalid' : ''}`}
                    placeholder=""
                    id="countryValue"
                    name="country"
                    components={animatedComponents}
                    options={countries.map((country: any) => ({ value: country.label, label: country.label }))}
                    value={patient.country ? { value: patient.country, label: patient.country } : null}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    onChange={(e: any) => { setPatient({ ...patient, country: e?.label ?? null }) }}
                    closeMenuOnSelect={true}
                    menuPosition="fixed"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-2">
                <div className="form-group">
                  <label>CEP *:</label>
                  {patientIsNational ? (
                    <InputMask
                      className={`form-control ${!patient.zipcode || patient.zipcode.length < 8 ? 'is-invalid' : ''}`}
                      mask="99999-999"
                      name="zipcode"
                      value={patient.zipcode ?? ''}
                      maskChar={null}
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control ${!patient.zipcode ? 'is-invalid' : ''}`}
                      name="zipcode"
                      value={patient.zipcode ?? ''}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-4 d-flex align-items-end">
                {patientIsNational && (
                  <Button type="button" className="cep-btn btn btn-primary" onClick={() => handleCEP({ value: patient.zipcode })}>
                    Buscar Endereço
                  </Button>
                )}
              </div>
              <div className="col-12"></div>
              <div className="col-12 col-sm-6 col-md-4">
                <div className="form-group">
                  <label>Endereço <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!patient.street ? 'is-invalid' : ''}`}
                    name="street"
                    value={patient.street}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Número <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!patient.number ? 'is-invalid' : ''}`}
                    name="number"
                    value={patient.number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4">
                <div className="form-group">
                  <label>Complemento</label>
                  <input
                    type="text"
                    placeholder=""
                    className="form-control"
                    name="complement"
                    value={patient.complement}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4">
                <div className="form-group">
                  <label>Bairro {patientIsNational && <span>*</span>}</label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${patientIsNational && !patient.area ? 'is-invalid' : ''}`}
                    name="area"
                    value={patient.area}
                    onChange={handleInputChange}
                    required={patientIsNational}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Cidade <span>*</span></label>
                  <input
                    type="text"
                    placeholder=""
                    className={`form-control ${!patient.city ? 'is-invalid' : ''}`}
                    name="city"
                    value={patient.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Estado <span>*</span></label>
                  {patientIsNational ? (
                    <Select
                      className={`react-select ${!patient.state ? 'is-invalid' : ''}`}
                      placeholder=""
                      id="stateValue"
                      name="state"
                      components={animatedComponents}
                      options={states.map((state: any) => ({ value: state.label, label: state.label }))}
                      value={patient.state ? { value: patient.state, label: patient.state } : null}
                      isSearchable={true}
                      isDisabled={false}
                      isLoading={false}
                      isRtl={false}
                      onChange={(e: any) => { setPatient({ ...patient, state: e.label }) }}
                      closeMenuOnSelect={true}
                      menuPosition="fixed"
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder=""
                      className={`form-control ${!patient.state ? 'is-invalid' : ''}`}
                      name="state"
                      value={patient.state}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="form-line my-4">
            <div className="row">
              <div className="col-12">
                <h6>Como você conheceu a Pro-Seed?</h6>
              </div>
            </div>
          </div>
          <div className="form-line">
            <div className="row">
              <div className="col-12 col-sm-6 col-mb-6 col-lg-3">
                <div className="form-group">
                  <label>Escolha uma opção <span>*</span></label>
                  <Select
                    className={`react-select ${!patient.where_meet_proseed ? 'is-invalid' : ''}`}
                    placeholder={<div>Selecione</div>}
                    components={animatedComponents}
                    options={whereMeetProseedOptions}
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={false}
                    isLoading={false}
                    isRtl={false}
                    closeMenuOnSelect={true}
                    value={patient.where_meet_proseed ? { value: patient.where_meet_proseed, label: patient.where_meet_proseed } : null}
                    onChange={(e: any) => { setPatient({ ...patient, where_meet_proseed: e.label }) }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="form-line d-flex my-3">
            <div className="custom-control custom-switch">
              <input
                className="custom-control-input d-flex align-items-center"
                type="checkbox"
                role="switch"
                onChange={handleHasPartner}
                checked={hasPartner}
                id="has_partner"
              />
              <label className="custom-control-label" htmlFor="has_partner"></label>
            </div>
            <h6>Tem companheiro(a) ou cônjuge?</h6>
          </div>
          {hasPartner && (
            <>
              <div className="form-line">
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nome e sobrenome <span>*</span></label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name="partner_name"
                        value={patient.partner_name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>Celular <span>*</span></label>
                      <PhoneInput
                        defaultCountry="br"
                        countries={countriesWithBrNewMask}
                        inputClassName="w-100"
                        name="partner_phone"
                        value={patient.partner_phone ?? ''}
                        onChange={(partner_phone) => setPatient({ ...patient, partner_phone })}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-line">
                <div className="row">
                  <div className="col-12 col-md-6 form-group mb-3">
                    <label>CPF / Passaporte:</label>
                    <div className="row m-0">
                      <div className="col-md-4 p-0">
                        <Select
                          className="react-select"
                          placeholder=""
                          id="partner_document_type"
                          name="partner_document_type"
                          isSearchable={false}
                          isClearable={false}
                          options={[{ value: 'cpf', label: 'CPF' }, { value: 'passport', label: 'Passaporte' }]}
                          value={patient.partner_document_type === 'passport' ? { value: 'passport', label: 'Passaporte' } : { value: 'cpf', label: 'CPF' }}
                          onChange={(e) => { handlePartnerDocumentType(e) }}
                        />
                      </div>
                      <div className="col-md-8 p-0 pl-3">
                        <input
                          id="partner_document"
                          name="partner_document"
                          className="form-control"
                          value={patient.partner_document ?? ''}
                          onChange={(e) => handlePartnerDocumentChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>E-mail <span>*</span></label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name="partner_email"
                        value={patient.partner_email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="register-footer mt-3">
          <div className="row">
            <div className="col-12">
              <div className="checkbox-terms d-flex">
                <input
                  type="checkbox"
                  className="custom-control"
                  id="terms_accepted"
                  name="terms_accepted"
                  value={patient.terms_accepted ? 'on' : 'off'}
                  onChange={handleCheckboxChange}
                />
                <label className="my-4 pl-2" htmlFor="terms_accepted">
                  Concordo com os
                  <Link to="https://proseed.com.br/termos-de-uso" target="_blank"> Termos de Uso</Link> e
                  <Link to="https://proseed.com.br/politica-de-privacidade" target="_blank"> Política de Privacidade</Link>
                </label>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <Link className="return-btn btn btn-primary mt-3" to="/">
                <MdKeyboardArrowLeft />
                Retornar
              </Link>
            </div>
            <div className="col-6 col-lg-3">
              <Button type="submit" className="register-btn btn btn-primary mt-3" disabled={!patient.terms_accepted}>
                Cadastrar
                <RiUserFollowLine />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Register
